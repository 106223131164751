import React from "react";
import DropDownOption from "../../DropDownOption";
import {Alert, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Loader from "../../Loader";
import styled from "styled-components";

const ContentWrapper = styled.div`
  max-width: 1190px;
  margin: auto;
  
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const InteractiveCard = styled(Card)`
  opacity: ${props => props.disabled ? '0.5' : '1'};
  padding: 5px;
  height: 60px;
  position: relative;
  display: flex;
  
  & > div {
    padding: 0;
  }
  & p{
    align-items: center!important;
  }
  &:hover{
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  @media (max-width: 430px) {
    margin: auto;
  }
`;

const FilterPanel = styled.div`
  display: flex;
  margin: 20px 24px 0;
  width: 100%;
  justify-content: space-between;
  & div,
  & button {
    margin-right: 10px;
  }
  & div:last-child,
  & button:last-child {
    margin-right: 0;
  }

  @media (max-width: 430px) {
    margin: 20px 0 0!important;
  }
`;

const FilterBlock = styled.div`
  display: flex;
  
  @media (max-width: 430px) {
    flex-direction: column;
    gap: 15px;
    width: 100%;
    & > div {
      margin-right: 0!important;
    }
    & button {
      width: 100%;
    }
  }
`;

const EmailContent = styled.span`
  color: #2884f6;
  cursor: text;
  font-size: inherit;
`;

const AvailableBadge = styled.span`
  border: 1px solid ${props => props.type === 'open' ? '#2e7d32' : '#d32f2f'};
  color: white;
  background: ${props => props.type === 'open' ? '#2e7d32' : '#d32f2f'};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
`;

const MTextField = styled(TextField)`
  width: 100%;
  background-color: white;
  margin: 20px 24px 0!important;
  
  @media (max-width: 430px) { 
    margin: 20px 0!important;
  }
`;

const StudentListGrid = styled(Grid)`
  max-width: 1190px;
  margin: 20px 0 0!important;
`;

const AlertContainer = styled.div`
  max-width: 625px;
  margin: auto;
  
  & > div {
    margin-top: 20px;
    display: flex;
  }
`;

const CardContainer = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 0!important;

  & > div {
    display: flex;
  }
  & > div > div {
    font-size: 14px;
  }
`;

const StudentControlPanelView = ({
  selectCourseHandler,
  selectModuleHandler,
  selectLectureHandler,
  courseFilterList,
  moduleFilterList,
  lectureFilterList,
  isCourseFilterBtnDisabled,
  isModuleFilterBtnDisabled,
  isLectureFilterBtnDisabled,
  searchFieldValue,
  onSearchFieldChange,
  isSelectAllDisabled,
  isSelectAllChecked,
  onSelectAllChange,
  selectAllValue,
  showAccessBtn,
  accessBtnHandler,
  studentsRequestHandler,
  isStudentsRequestBtnDisabled,
  isStudentListLoading,
  isStudentListEmpty,
  onStudentCardHandler,
  windowWidth,
  isCardChecked,
  studentList,
  isOpenedLecture,
}) => {
  return (
    <ContentWrapper>
      <FilterPanel>
        <FilterBlock>
          <DropDownOption
            onSelectHandler={selectCourseHandler}
            variant="outlined"
            list={courseFilterList}
            isMainBtnDisabled={isCourseFilterBtnDisabled}
          />
          <DropDownOption
            onSelectHandler={selectModuleHandler}
            variant="outlined"
            list={moduleFilterList}
            isMainBtnDisabled={isModuleFilterBtnDisabled}
          />
          <DropDownOption
            onSelectHandler={selectLectureHandler}
            variant="outlined"
            list={lectureFilterList}
            isMainBtnDisabled={isLectureFilterBtnDisabled}
          />
        </FilterBlock>
      </FilterPanel>
      <MTextField
        id="outlined-name"
        label="Поиск"
        value={searchFieldValue}
        onChange={onSearchFieldChange}
      />
      <FilterPanel>
        <FilterBlock>
          <FormControlLabel
            control={<Checkbox />}
            disabled={isSelectAllDisabled}
            checked={isSelectAllChecked}
            onChange={onSelectAllChange}
            label={selectAllValue}
          />
        </FilterBlock>
        <FilterBlock>
          {
            showAccessBtn ? (
              <>
                <Button
                  variant="outlined"
                  color='error'
                  onClick={() => accessBtnHandler(false)}
                >Закрыть</Button>
                <Button
                  variant="outlined"
                  color='success'
                  onClick={() => accessBtnHandler(true)}
                >Открыть доступ</Button>
              </>
            ) : (
              <Button
                variant="contained"
                color='success'
                onClick={studentsRequestHandler}
                disabled={isStudentsRequestBtnDisabled}
              >Получить список</Button>
            )
          }
        </FilterBlock>
      </FilterPanel>
      <StudentListGrid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        {
          isStudentListLoading ? (
            <Loader />
          ) : (
            isStudentListEmpty ? (
              <AlertContainer>
                <Alert variant="outlined" severity="info">
                  Необходимо запросить список студентов
                </Alert>
              </AlertContainer>
            ) : (
              <>
                {
                  studentList.map((subEl, index) => (
                    <Grid item xs={windowWidth > 500 ? 6 : 12} key={index}>
                      <InteractiveCard onClick={() => onStudentCardHandler(subEl.id)}>
                        <CardContainer>
                          <div>
                            <Checkbox checked={isCardChecked(subEl.id)} color="success" />
                            <div>
                              {index + 1}. {subEl.user.firstName} {subEl.user.lastName} &nbsp; <br/>
                              <EmailContent>{subEl.user.email}</EmailContent>
                            </div>
                          </div>
                          {
                            isOpenedLecture(subEl) ? (
                              <AvailableBadge type="open">открыт</AvailableBadge>
                            ) : (
                              <AvailableBadge type="close">закрыт</AvailableBadge>
                            )
                          }
                        </CardContainer>
                      </InteractiveCard>
                    </Grid>
                  ))
                }
              </>
            )
          )
        }
      </StudentListGrid>
    </ContentWrapper>
  );
};

export default StudentControlPanelView;