import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainPageTitle from "../../containers/MainPageTitle";
import {setUsersList} from "../../utils/reducers/repoReducer";
import {Card, CardContent, CardMedia, Grid, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import app from '../../firebase'
import Button from "@mui/material/Button";
import CourseEditPage from "./CourseEditPage";
import {createCourseStaticDBArchitecture, createDBArchitecture} from "../../utils/services/createCourseDBArchitecture";
import {materialCollection} from "../../data/courseData";
import Unity3DaysIntensive_Data from "../../externalData/Unity3DaysIntensive/courseInfo.json";
import CSharp_Data from "../../externalData/CSharp/courseInfo.json";
import FrontEnd_Data from "../../externalData/FrontEnd/courseInfo.json";
import DigitalArt_Data from "../../externalData/DigitalArt/courseInfo.json";
import KerbalSpace_Data from "../../externalData/KerbalSpace/courseInfo.json";
import Minecraft_Data from "../../externalData/Minecraft/courseInfo.json";
import Modelling_Data from "../../externalData/Modelling/courseInfo.json";
import Python_Data from "../../externalData/Python/courseInfo.json";
import Unity_Data from "../../externalData/Unity/courseInfo.json";
import UnrealEngine_Data from "../../externalData/UnrealEngine/courseInfo.json";
import WebDesign_Data from "../../externalData/WebDesign/courseInfo.json";
import Target_Data from "../../externalData/Target/courseInfo.json";
import MentoringByKanatBaktybek_Data from "../../externalData/MentoringByKanatBaktybek/courseInfo.json";
import {createFreelanceDBArchitecture} from "../../utils/services/createFreelanceDBArchitecture";
import {createShopDBArchitecture} from "../../utils/services/createShopDBArchitecture";
import {deleteUserData} from "../../utils/services/firestoreHandlers";

const ContentWrapper = styled('div')`
  max-width: 1196px;
  margin: auto;
`;

const InteractiveCard = styled(Card)`
  opacity: ${props => props.disabled ? '0.5' : '1'};
  padding: 5px;
  height: 60px;
  position: relative;
  
  &:hover{
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  @media (max-width: 430px) {
    margin: auto;
  }
`;

const EmailContent = styled('span')`
  color: #2884f6;
  cursor: text;
  font-size: 16px;
`;

const UserDeleteContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const Admin = () => {
  const dispatch = useDispatch();
  const usersList = useSelector(state => state.repos.usersList);
  const {innerWidth: width, innerHeight: height} = window;
  const [inputValue, setInputValue] = useState('');
  const [userUIDList, setUserUIDList] = useState([]);
  const [nativeUsersList, setNativeUsersList] = useState('');
  const [showCourseEditPage, setShowCourseEditPage] = useState(false);
  const [userUIDToDelete, setUserUIDToDelete] = useState('');

  useEffect(async () => {
    const snapshot = await app.firestore().collection('users').get()
    setNativeUsersList(snapshot.docs)
    const sortedData = snapshot.docs.map(doc => doc.data()).sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))

    if (snapshot.docs) {
      const uidList = [];
      sortedData.map(el => (
        snapshot.docs.forEach(doc => doc.data().email === el.email ? uidList.push(doc.id) : null)
      ))

      setUserUIDList(uidList);
    }
    dispatch(setUsersList(sortedData));
  }, [])

  const filterHandler = (event) => {
    setInputValue(event.target.value.toLowerCase());
  }

  useEffect(async () => {
    const coursesData = [
      Unity3DaysIntensive_Data,
      CSharp_Data,
      FrontEnd_Data,
      DigitalArt_Data,
      KerbalSpace_Data,
      Minecraft_Data,
      Modelling_Data,
      Python_Data,
      Unity_Data,
      UnrealEngine_Data,
      WebDesign_Data,
      Target_Data,
      MentoringByKanatBaktybek_Data
    ];  // Изменять значение в зависимости от количества курсов
    // const i = 12;
    // for(let j = 0; j < coursesData[i].lectureCount_DB.length; j++){
    //   for(let k = 0; k < coursesData[i].lectureCount_DB[j]; k++) {
        // console.log('asd')
        // await updateDoc(doc(db, "courseData", "courses"), {
    //       [`${i}.${j}.${k}.pageCount`]: JSON.stringify(materialCollection()[i][j][k].pageCount),
    //       [`${i}.${j}.${k}.sectionName`]: JSON.stringify(materialCollection()[i][j][k].sectionName),
    //       [`${i}.${j}.${k}.pageType`]: JSON.stringify(materialCollection()[i][j][k].pageType),
    //       [`${i}.${j}.${k}.pageFlow`]: JSON.stringify(materialCollection()[i][j][k].pageFlow)
    //     })
      // }
    // }


    //FORCE-UPDATE-DB-STRUCTURE
    // await updateDoc(doc(db, "freelance", "Be4ZjNRogIhU9l2zTjKdHfcZeGm2"), createFreelanceDBArchitecture())
    // await updateDoc(doc(db, "shop", "Be4ZjNRogIhU9l2zTjKdHfcZeGm2"), createShopDBArchitecture())
    // await updateDoc(doc(db, "courses", "sklZspp9LjRxvFCniUiw2nzHgiS2"), createDBArchitecture())
    // await updateDoc(doc(db, "courseDB", "sklZspp9LjRxvFCniUiw2nzHgiS2"), {
    //  role: ROLES_LIST.STUDENT,
    // })

    const userCount = 50;
    if (userUIDList.length) {
      for (const el of userUIDList) {
        const index = userUIDList.indexOf(el);
        if (index < userCount) {
          // //FORCE-UPDATE-DB-STRUCTURE
          // await updateDoc(doc(db, "courses", el), createDBArchitecture());
          // console.log(el, `${index + 1} / ${userCount}`)
        }
      }
    }
  }, [userUIDList])

  return (
    <>
      <MainPageTitle>Ученики</MainPageTitle>
      <ContentWrapper>
        <Button
          variant="contained"
          color='success'
          size="small"
          onClick={() => setShowCourseEditPage(prev => !prev)}
        >{showCourseEditPage ? '<= Доступы для учеников' : 'Редактировать курсы'}</Button>
        {
          showCourseEditPage ? <CourseEditPage/> : (
            <>
              <UserDeleteContainer>
                <TextField
                  sx={{width: '100%', backgroundColor: 'white', margin: '20px 20px 30px 0'}}
                  id="outlined-name"
                  label="UID to delete"
                  value={userUIDToDelete}
                  onChange={(e) => setUserUIDToDelete(e.target.value)}
                />
                <Button
                  variant="contained"
                  color='error'
                  onClick={async () => await deleteUserData(userUIDToDelete)}
                >Delete</Button>
              </UserDeleteContainer>

              <TextField
                sx={{width: '100%', backgroundColor: 'white', margin: '20px 0 30px 0'}}
                id="outlined-name"
                label="Поиск"
                value={inputValue}
                onChange={filterHandler}
              />
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {
                  usersList ?
                    usersList.filter(el => {
                      if (el.firstName.toLowerCase().includes(inputValue)
                        || el.lastName.toLowerCase().includes(inputValue)
                        || el.email.toLowerCase().includes(inputValue)) return el
                    }).map((elem, index) => {
                      let uid = null

                      if (nativeUsersList) {
                        nativeUsersList.forEach(doc => doc.data().email === elem.email ? uid = doc.id : null)
                      }

                      return (
                        <Grid item xs={width > 500 ? 6 : 12} key={index}>
                          <Link to={`student?userId=${index}&uid=${uid}`}>
                            <InteractiveCard sx={{display: 'flex'}}>
                              <CardContent sx={{padding: '16px'}}>
                                <Typography variant="body2" fontSize="16px" height="52px">
                                  {index + 1}. {elem.firstName} {elem.lastName}
                                  <EmailContent>{elem.email}</EmailContent>
                                </Typography>
                              </CardContent>
                            </InteractiveCard>
                          </Link>
                        </Grid>
                      )

                    })
                    : null
                }
              </Grid>
            </>
          )
        }


      </ContentWrapper>
    </>
  )
}

export default Admin