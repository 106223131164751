import React, {useEffect, useState} from "react";
import LoaderWithOverlay from "../../LoaderWithOverlay";
import MainPageTitle from "../../../containers/MainPageTitle";
import styled from "styled-components";
import {Alert, Grid, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loader from "../../Loader";
import _ from 'lodash';
import {SideNavigation} from "../../SideNavigation";
import {hoverColor, mainColor, secondColor} from "../../../constants/colors";
import Button from "@mui/material/Button";
import TeacherOptionLine from "../TeacherOptionLine";
import {
  deleteCourseCollection, deleteLectureDocument,
  deleteModuleCollection,
  getCoursesInfoFromDB, getModuleDataFromDB, updateCourseCollection, updateModuleCollection
} from "../../../utils/services/firestoreHandlers";
import {getCurrentTimeInMoscow, getItemById} from "../../../utils/services";
import Logo from "../../../media/platformUpdateImg.png";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {setCourseData} from "../../../utils/reducers/repoReducer";
import {createDBArchitecture} from "../../../utils/services/createCourseDBArchitecture";
import CourseEditPage from "../../Admin/CourseEditPage";
import PageContent from "../../../containers/PageContent";
import {getDownloadMaterials} from "../../../data/courseData";
import AlertDialogSlide from "../../AlertDialogSlide";

const ContentWrapper = styled('div')`
  padding-right: 320px;

  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const SectionTitle = styled('h1')`
  text-align: center;
  font-size: 20px;
  padding: 12px;
  margin: 20px 0;
  
  @media (max-width: 430px) {
    margin: 50px 0 20px 0;
    font-size: 0.8rem;
  }
`

const SectionsWrapper = styled('div')`
  background: ${mainColor};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  border-radius: 8px 0 0 8px;
  z-index: 200;
  color: white;
  overflow-y: auto;
  padding-bottom: 40px;
  
  &::-webkit-scrollbar {
    width: 0.4em;
    background-color: #C4C4C4;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  };
  &::-webkit-scrollbar-thumb {
    border: 1px solid rgba(136, 136, 136, 0.5);
    border-radius: 20px;
  }
`

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

const Divider = styled('hr')`
  background-color: #d7d9da;
  height: 1px;
  border: none;
  margin: 0;
`

const SectionOptionLine = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;

  & button {
    color: white;
    border-color: white;
    font-size: 12px;
  }

  & button:hover {
    color: #bbb8b8;
    border-color: #bbb8b8;
  }
`;

const AddContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
  & button {
    margin-right: 10px;
  }
`

const ElementContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px dashed green;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 20px;
`

const LessonTextField = styled(TextField)`
  & input {
    font-weight: 600!important;
  }
`;

const ModulePanel = () => {
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const moduleData = useSelector(state => state.repos.moduleData);
  const userData = useSelector(state => state.repos.userData);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleInfo, setModuleInfo] = useState({});
  const [lectureList, setLectureList] = useState({});
  const [selectedLectureId, setSelectedLectureId] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  const [urlParameters, setUrlParameters] = useSearchParams();
  const courseId = +urlParameters.get('courseId');
  const moduleId = +urlParameters.get('moduleId');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const successUpdateCallback = async () => {
    await getCoursesInfoFromDB(userData, dispatch);
    setIsLoading(false);
  }

  const getIndexOfCurrentModule = () => getItemById(coursesInfo, courseId)?.moduleIds?.indexOf(moduleId) || 0;

  const getNewLectureId = () => lectureList.length ? lectureList.reduce((max, obj) => (obj.id > max ? obj.id : max), 0) : -1
  const updateCourseAfterDeleteModule = () => {
    updateCourseCollection(`course_${courseId}`, () => successUpdateCallback().then(r => navigate(`/teacher/course?courseId=${courseId}`)), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        moduleNames: getItemById(coursesInfo, courseId).moduleNames?.filter((el, index) => index !== getIndexOfCurrentModule()),
        moduleIcons: getItemById(coursesInfo, courseId).moduleIcons?.filter((el, index) => index !== getIndexOfCurrentModule()),
        moduleIds: getItemById(coursesInfo, courseId).moduleIds?.filter((el, index) => index !== getIndexOfCurrentModule()),
        lectureCount: getItemById(coursesInfo, courseId).lectureCount?.filter((el, index) => index !== getIndexOfCurrentModule()),
      }
    })
  }

  const updateCourseAfterDeleteLesson = () => {
    updateCourseCollection(`course_${courseId}`, () => successUpdateCallback(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        lectureCount: getItemById(coursesInfo, courseId).lectureCount?.map((el, index) => index === getIndexOfCurrentModule() ? el - 1 : el),
      }
    })
    setSelectedLectureId(0);
  }

  const updateCourseAfterUpdateLesson = () => {
    updateCourseCollection(`course_${courseId}`, () => successUpdateCallback(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        moduleNames: getItemById(coursesInfo, courseId).moduleNames?.map((el, index) => index === getIndexOfCurrentModule() ? moduleInfo.name : el),
        lectureCount: getItemById(coursesInfo, courseId).lectureCount?.map((el, index) => index === getIndexOfCurrentModule() ? moduleInfo.lectureCount : el),
        moduleIds: getItemById(coursesInfo, courseId).moduleIds?.map((el, index) => index === getIndexOfCurrentModule() ? moduleInfo.id : el),
      }
    })
  }

  const updateCourseAfterCreateLesson = () => {
    updateCourseCollection(`course_${courseId}`, () => successUpdateCallback(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        lectureCount: getItemById(coursesInfo, courseId).lectureCount?.map((el, index) => index === getIndexOfCurrentModule() ? moduleInfo.lectureCount + 1 : el),
      }
    })
  }

  const updateModuleAfterCreateLesson = () => {
    updateModuleCollection(`course_${courseId}`, `module_${moduleId}`, 'info', () => updateCourseAfterCreateLesson(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        lectureCount: moduleInfo.lectureCount + 1,
        lectureNames: [ ...moduleInfo.lectureNames, `Новый урок` ],
      }
    })
  }

  const updateModuleAfterUpdateLesson = () => {
    updateModuleCollection(`course_${courseId}`, `module_${moduleId}`, 'info', () => updateCourseAfterUpdateLesson(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        name: moduleInfo.name,
        lectureNames: lectureList.map(el => el.sectionName),
      }
    })
  }

  const updateModuleAfterDeleteLesson = () => {
    updateModuleCollection(`course_${courseId}`, `module_${moduleId}`, 'info', () => updateCourseAfterDeleteLesson(), {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        name: moduleInfo.name,
        lectureNames: moduleInfo.lectureNames?.filter((el, index) => index !== selectedLectureId),
        lectureCount: moduleInfo.lectureCount > 0 ? moduleInfo.lectureCount - 1 : moduleInfo.lectureCount,
      }
    })
  }

  const onDeleteModuleHandler = () => {
    setIsLoading(true);
    deleteModuleCollection(`course_${courseId}`, `module_${moduleId}`, updateCourseAfterDeleteModule);
  }

  const onDeleteLessonHandler = () => {
    setIsLoading(true);
    deleteLectureDocument(`course_${courseId}`, `module_${moduleId}`, `lecture_${lectureList[selectedLectureId].id}`, updateModuleAfterDeleteLesson);
  }

  const onCreateLesson = () => {
    if (!_.isEmpty(moduleData)) {
      setIsLoading(true);

      updateModuleCollection(
        `course_${courseId}`,
        `module_${moduleId}`,
        `lecture_${getNewLectureId() + 1}`,
        () => updateModuleAfterCreateLesson(), {
        id: getNewLectureId() + 1,
        sectionName: `Новый урок`,
        pageFlow: [
          { pageSecondTitles: "Заголовок 1" },
        ]
      })
    }
  }

  const onModuleInfoChange = (fieldName, value) => {
    setModuleInfo(prev => ({ ...prev, [fieldName]: value }))
  };

  const onLectureNamesChange = (value) => {
    setModuleInfo(prev => ({
     ...prev,
     lectureNames: prev.lectureNames.map((el, index) => (index === selectedLectureId ? value : el ))
    }));
    setLectureList(prev => prev.map((el, index) => (
     index === selectedLectureId ? { ...el, sectionName: value } : { ...el }
    )))
  }

  const onLectureItemChange = (value, fieldId, fieldKey) => {
    setLectureList(prev => prev.map((lecture, index) => index === selectedLectureId ? ({
        ...lecture,
        pageFlow: lecture.pageFlow.map((el, index) => index === fieldId ? ({ ...el, [fieldKey]: value }) : ({ ...el }))
      }) : lecture
    ))
  };

  const addLectureItemHandler = (elType) => {
    setLectureList(prev => prev.map((lecture, index) => index === selectedLectureId ? ({
        ...lecture,
        pageFlow: [...lecture.pageFlow, { [elType]: '' }]
      }) : lecture
    ))
  }

  const deleteLectureItemHandler = (fieldId) => {
    setLectureList(prev => prev.map((lecture, index) => index === selectedLectureId ? ({
        ...lecture,
        pageFlow: lecture.pageFlow.filter((el, index) => index !== fieldId )
      }) : lecture
    ))
  }

  const onSaveHandler = async () => {
    try {
      setIsLoading(true);
      updateModuleCollection(`course_${courseId}`, `module_${moduleId}`, `lecture_${lectureList[selectedLectureId].id}`, () => updateModuleAfterUpdateLesson(), {
        sectionName: lectureList[selectedLectureId].sectionName,
        pageFlow: lectureList[selectedLectureId].pageFlow,
      })
      console.log('Данные сохранены')
    } catch (e) {
      console.error(e)
      alert('Ошибка при попытке сохранения данных!')
    }
  }

  const DeleteBtnStyle = {
    border: 'none',
    padding: '4px',
    fontSize: '12px',
    color: 'gray',
    textAlign: 'end',
  }

  useEffect(() => {
    if (coursesInfo?.length) {
      //Loading data
      getModuleDataFromDB(courseId, moduleId, dispatch).then(r => {});
    }
  }, [coursesInfo])

  useEffect(() => {
    if(!_.isEmpty(moduleData)) {
      //Saving data in redux
      setModuleInfo(moduleData[0].info);
      setLectureList(moduleData.slice(1, moduleData.length).sort((a, b) => a.id - b.id));
    }
  }, [moduleData])

  return (
    <ContentWrapper>
      <MainPageTitle>Редактор модуля</MainPageTitle>

      <Grid style={{ maxWidth: '1190px', margin: 'auto' }} container spacing={2} marginBottom={10}>
        {
          !_.isEmpty(moduleInfo) ? (
            <>
              {
                <SectionsWrapper>
                  <SectionTitle>{moduleInfo.name}</SectionTitle>
                  <Divider />
                  <SideNavigation
                    sectionTitles={moduleInfo.lectureNames}
                    onClickHandler={(id) => setSelectedLectureId(id)}
                    disableHandler={() => {}}
                    isActiveSection={() => {}}
                    isSectionDone={() => {}}
                  />
                  <SectionOptionLine>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={onCreateLesson}
                    >Добавить урок</Button>
                  </SectionOptionLine>
                </SectionsWrapper>
              }
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={moduleInfo.name}
                  variant="outlined"
                  onChange={(e) => onModuleInfoChange('name', e.target.value)}
                  label="Название модуля"
                />
                <AlertDialogSlide
                  mainBtnText="удалить модуль"
                  mainBtnStyle={DeleteBtnStyle}
                  dialogTitle="Уверены, что хотите удалить модуль?"
                  dialogDescription = "Удаление материала займет некоторое время"
                  onAgreeHandler={onDeleteModuleHandler}
                  agreeBtnText="Удалить"
                />
                {
                  !_.isEmpty(lectureList) ? (
                    <>
                      <Typography variant="h6" textAlign="center" marginBottom="20px">Структура урока</Typography>
                      <AddContainer>
                        <Button
                          variant="outlined"
                          color='success'
                          size="small"
                          onClick={() => addLectureItemHandler("pageSecondTitles")}
                        >Заголовок</Button>
                        <Button
                          variant="outlined"
                          color='success'
                          size="small"
                          onClick={() => addLectureItemHandler("pageText")}
                        >Обычный текст</Button>
                        <Button
                          variant="outlined"
                          color='success'
                          size="small"
                          onClick={() => addLectureItemHandler("pageVideo")}
                        >Видео</Button>
                        <Button
                          variant="contained"
                          color='warning'
                          size="small"
                          onClick={() => setShowPreview(prev => !prev)}
                        >Предпросмотр</Button>
                      </AddContainer>
                      {
                        showPreview ? (
                          <>
                            <ElementContainer style={{ flexDirection: 'column' }}>
                              <PageContent pageData={lectureList[selectedLectureId].pageFlow} />
                            </ElementContainer>
                            <hr style={{ margin: '80px' }}/>
                          </>
                        ) : null
                      }
                      <LessonTextField
                        id="lesson-name"
                        fullWidth
                        value={lectureList[selectedLectureId]?.sectionName}
                        variant="outlined"
                        onChange={(e) => onLectureNamesChange(e.target.value)}
                        style={{ marginBottom: '20px' }}
                        label="Название урока"
                      />
                      <>
                        {
                          lectureList[selectedLectureId]?.pageFlow.map((el, index) => (
                            <ElementContainer>
                              {
                                Object.keys(el)[0] === 'pageSecondTitles' ? <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  value={el.pageSecondTitles}
                                  variant="outlined"
                                  onChange={(e) => {
                                    onLectureItemChange(e.target.value, index, "pageSecondTitles");
                                  }}
                                  label="Заголовок"
                                /> : Object.keys(el)[0] === 'pageText' ? <TextField
                                  id="filled-multiline-static"
                                  value={el.pageText}
                                  onChange={(e) => {
                                    onLectureItemChange(e.target.value, index, "pageText");
                                  }}
                                  fullWidth
                                  label="Обычный текст"
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                /> : Object.keys(el)[0] === 'pageVideo' ? <TextField
                                  id="outlined-basic"
                                  label="Ссылка на видео"
                                  value={el.pageVideo}
                                  onChange={(e) => {
                                    onLectureItemChange(e.target.value, index, "pageVideo");
                                  }}
                                  variant="outlined"
                                  fullWidth
                                /> : null
                              }
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                                style={{ marginLeft: '20px' }}
                                onClick={() => deleteLectureItemHandler(index)}
                              >Удалить</Button>
                            </ElementContainer>
                          ))
                        }
                      </>
                      <TeacherOptionLine onSaveHandler={onSaveHandler} onDeleteHandler={onDeleteLessonHandler} deleteMainBtnText="Удалить урок" />
                    </>
                  ) : (
                    <div style={{ maxWidth: '625px', margin: 'auto' }}>
                      <Alert variant="outlined" severity="info" sx={{ marginTop: '20px', display: 'flex' }}>Нажми "Добавить урок", чтобы создать первый урок в этом модуле</Alert>
                    </div>
                  )
                }
              </Grid>
            </>
          ) : <Loader />
        }
      </Grid>
      { isLoading && <LoaderWithOverlay /> }
    </ContentWrapper>
  )
}

export default ModulePanel;