import item_5 from "../../../media/platformer2D_screen.png";
import item_3 from "../../../media/apk.png";
import flat_default from "../../../media/apartments/flat_default.jpg";
import flat_economy_1 from "../../../media/apartments/flat_economy_1.jpg";
import flat_economy_2 from "../../../media/apartments/flat_economy_2.jpg";
import flat_standard_1 from "../../../media/apartments/flat_standard_1.jpg";
import flat_standard_2 from "../../../media/apartments/flat_standard_2.jpg";
import flat_premium_1 from "../../../media/apartments/flat_premium_1.jpg";
import flat_lux_1 from "../../../media/apartments/flat_lux_1.jpg";
import flat_lux_2 from "../../../media/apartments/flat_lux_2.jpg";
import flat_lux_3 from "../../../media/apartments/flat_lux_3.jpg";
import item_2 from "../../../media/asset.png";
import unity3DaysIntensive from "../../../media/courseWallpaper/unity_3Days_intensive.png";
import unity_icon from "../../../media/courseWallpaper/unity.png";
import frontend_icon from "../../../media/courseWallpaper/frontend.png";
import minecraft_icon from "../../../media/courseWallpaper/minecraft.png";
import python_icon from "../../../media/courseWallpaper/python.png";
import figma_icon from "../../../media/courseWallpaper/figma.png";
import adobe_illustrator_icon from "../../../media/courseWallpaper/adobe_illustrator.png";
import blender_icon from "../../../media/courseWallpaper/blender.png";
import unreal_engine_icon from "../../../media/courseWallpaper/unreal_engine.png";
import ksp_icon from "../../../media/courseWallpaper/kerbal_space_programm.png";
import target_icon from "../../../media/courseWallpaper/target.png";
import mentoringByKanatBaktybek_icon from "../../../media/courseWallpaper/Mentoring-by-Kanat-Baktybek.png";

export const createShopDBArchitecture = () => {
  return ({
    items: [
      {name: 'Platform2D: Ассет пак для курса по С#', img: item_5, price: 500, isAvailable: true, isSold: false, description: 'Игра порадует классическим ближним боем, это круто...', link: 'https://drive.google.com/file/d/14hhE7c9RgSM2YR8u_VzL9ml2mShxUxjw/view?usp=sharing', tag: 'asset', tagName: 'unity ассет' },
      {name: 'Игра: RocketC', img: item_3, price: 4900, isAvailable: true, isSold: false, description: 'Игра порадует классическим ближним боем, это круто...', link: 'https://drive.google.com/file/d/1zze3OO4UKy6x7wgol3OU8_lCTqEDeey-/view?usp=sharing', tag: 'other', tagName: 'игра'},
      {name: 'Стартовая Студия: "Уютное гнездышко"', img: flat_default, price: 500, gCoinGain: 0, isAvailable: false, isSold: true, description: 'Стартовая квартира без бонусов', tag: 'apartment', tagName: 'Эконом'},
      {name: 'Студия: "Бюджетный Уголок"', img: flat_economy_1, price: 7000, gCoinGain: 100, isAvailable: true, isSold: false, description: 'Просторная эконом квартира, которая готова приносить +100 GCoin каждый день', tag: 'apartment', tagName: 'Эконом'},
      {name: 'Студия: "Уютная Студия"', img: flat_economy_2, price: 10000, gCoinGain: 150, isAvailable: true, isSold: false, description: 'Еще один вариант эконома, приносит +150 GCoin каждый день', tag: 'apartment', tagName: 'Эконом'},
      {name: 'Квартира: "Удобное Жилище"', img: flat_standard_1, price: 28000, gCoinGain: 450, isAvailable: true, isSold: false, description: 'Стандартная квартира в стиле этно. Приносит +450 GCoin каждый день', tag: 'apartment', tagName: 'Стандарт'},
      {name: 'Квартира: "Современный Комфорт"', img: flat_standard_2, price: 33500, gCoinGain: 550, isAvailable: true, isSold: false, description: 'Уютный и просторный стандарт. Может приносить +550 GCoin каждый день', tag: 'apartment', tagName: 'Стандарт'},
      {name: 'Апартаменты: "Премиум Сьют"', img: flat_premium_1, price: 46000, gCoinGain: 900, isAvailable: true, isSold: false, description: 'Уникальный и приятный премиум. Приносит +900 GCoin каждый день', tag: 'apartment', tagName: 'Премиум'},
      {name: 'Пентхаус: "Высокий Стиль"', img: flat_lux_1, price: 62500, gCoinGain: 1250, isAvailable: true, isSold: false, description: 'Дорогой люкс. Готов приносить +1250 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
      {name: 'Пентхаус: "Эксклюзивный Дворец"', img: flat_lux_2, price: 108000, gCoinGain: 1950, isAvailable: true, isSold: false, description: 'Практически личный дворец. Приносит +1950 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
      {name: 'Пентхаус: "Райская Резиденция"', img: flat_lux_3, price: 210000, gCoinGain: 2450, isAvailable: true, isSold: false, description: 'Уютный, но дорогой люкс. Редкий. Готов приносить +2450 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
      {name: 'Ассет для 2D игры', img: item_2, price: 9700, isAvailable: false, isSold: false, description: 'Игра порадует классическим ближним боем, это круто...', tag: 'asset', tagName: 'unity ассет'},
      {name: 'Разработка 2D платформера на Unity за 3 дня', img: unity3DaysIntensive, price: 0, isAvailable: true, isSold: false, description: 'Обучение основам работы с Unity и сборка игры', tag: 'course', tagName: 'курс'},
      {name: 'Разработка игр на Unity', img: unity_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение созданию собственных игр на движке Unity', tag: 'course', tagName: 'курс'},
      {name: 'Разработка сайтов', img: frontend_icon, price: 28800, isAvailable: false, isSold: false, description: 'Разработка современных веб-сайтов на Javascript, React', tag: 'course', tagName: 'курс'},
      {name: 'Программирование на Minecraft', img: minecraft_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение программированию на базе Minecraft Education', tag: 'course', tagName: 'курс'},
      {name: 'Программирование на Python', img: python_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение программированию на языке Python', tag: 'course', tagName: 'курс'},
      {name: 'Веб-дизайн', img: figma_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение созданию красочных дизайнов сайтов', tag: 'course', tagName: 'курс'},
      {name: 'Цифровой рисунок (Digital Art)', img: adobe_illustrator_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение работы с adobe illustrator и анимации', tag: 'course', tagName: 'курс'},
      {name: '3D моделирование', img: blender_icon, price: 28800, isAvailable: false, isSold: false, description: 'Создание собственных трехмерных объектов в Blender', tag: 'course', tagName: 'курс'},
      {name: 'Разработка игр на Unreal Engine', img: unreal_engine_icon, price: 28800, isAvailable: false, isSold: false, description: 'Разработка игр на Unreal Engine', tag: 'course', tagName: 'курс'},
      {name: 'Космо-проектирование KSP', img: ksp_icon, price: 28800, isAvailable: false, isSold: false, description: 'Обучение конструированию ракет, запусков на дальние орбиты и построение траектории', tag: 'course', tagName: 'курс'},
      {name: 'Фокус-группа ТАРГЕТ С НУЛЯ', img: target_icon, price: 48800, isAvailable: false, isSold: false, description: 'Курс для тех, кто хочет научиться запускать таргет с нуля и зарабатывать на этом', tag: 'course', tagName: 'курс'},
      {name: 'Канат Бактыбек. Наставничество', img: mentoringByKanatBaktybek_icon, price: 48800, isAvailable: false, isSold: false, description: 'Запусти свой магазин и выйти на стабильные продажи за 60 дней', tag: 'course', tagName: 'курс'},
    ]
  })
}