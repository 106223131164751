import React, {useEffect, useState} from "react";
import MainPageTitle from "../../../containers/MainPageTitle";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Card, CardContent, CardMedia, Grid, TextField, Typography} from "@mui/material";
import CustomBadge from "../../CustomBadge";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import GamePointsBadge from "../../GamePointsBadge";
import {getMaxCourseAward} from "../../../utils/services/ServiceEconomics";
import {
  getFullLessonCountInCourse,
  getFullModuleCountInCourse
} from "../../../utils/services/сalculationService/courseProgress";
import {getCurrentTimeInMoscow, getLocalTimeFromUnixTime} from "../../../utils/services";
import Loader from "../../Loader";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AlertDialogSlide from "../../AlertDialogSlide";
import TeacherOptionLine from "../TeacherOptionLine";
import {
  deleteCourseCollection,
  getCoursesInfoFromDB,
  updateCourseCollection, updateModuleCollection
} from "../../../utils/services/firestoreHandlers";
import Logo from '../../../media/platformUpdateImg.png';
import LoaderWithOverlay from "../../LoaderWithOverlay";
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import _ from "lodash";

const ContentWrapper = styled('div')`
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const CardMediaMobile = styled(CardMedia)`
  @media (max-width: 430px) {
    position: absolute!important;
    width: 100px!important;
    height: 41px!important;
    border-radius: 0 0px 0px 15px!important;
    right: 0!important;
    top: 0!important;
    padding: 0!important;
  }
`;

const AwardStats = styled('div')`
  display: flex;
`;

const DetailsBox = styled('span')`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 430px) {
    & a {
      height: 26px;
    }
    & button {
      font-size: 0.4rem!important;
      font-weight: 600;
      height: 22px;
    }
  }
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

const InteractiveCard = styled(Card)`
  display: flex;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  position: relative;

  &:hover {
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  &:before {
    position: absolute;
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: #2e7d32;
  }
  
  @media (max-width: 430px) {
    margin: auto;
  }
`;


const AddBtnStyle = {
  width: '100%',
  height: '80px',
  border: '2px dashed',
  fontSize: '26px',
  fontWeight: 600
}

const CardWrapper = ({ moduleName, index, disabled, lectureCount, rightCard }) => (
  <InteractiveCard
    disabled={disabled}
    rightCard={rightCard}
  >
    <CardContent style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <TypographyMobile gutterBottom variant="h6" component="div" fontSize="16px" fontWeight="600">
        {index + 1}. {moduleName}
      </TypographyMobile>
      <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Количество домашних заданий и тестов">
        <DetailsBox>
          <PlayLessonIcon sx={{ marginRight: '4px', width: '20px' }} /> Занятий: {lectureCount}
        </DetailsBox>
      </Tooltip>
    </CardContent>
  </InteractiveCard>
)

const CoursePanel = () => {
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const courseData = useSelector(state => state.repos.courseData);
  const userData = useSelector(state => state.repos.userData);
  const [urlParameters, setUrlParameters] = useSearchParams();
  const [courseParams, setCourseParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const courseId = urlParameters.get('courseId');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const successUpdateCallback = async () => {
    await getCoursesInfoFromDB(userData, dispatch);
    setIsLoading(false);
  }

  const getIdForNewModule = () => {
    if (courseParams?.moduleIds?.length) return Math.max(...courseParams.moduleIds) + 1;
    return 0;
  }

  const updateCourseData = () => {
    updateCourseCollection(`course_${courseId}`, successUpdateCallback, {
      info: {
        updatedAt: getCurrentTimeInMoscow(),
        moduleNames: [...courseParams.moduleNames, 'Новый модуль'],
        moduleIds: [...courseParams.moduleIds, getIdForNewModule()],
        moduleIcons: [...courseParams.moduleIcons, Logo],
        lectureCount: [...courseParams.lectureCount, 0],
      }
    })
  }

  const createModuleHandler = () => {
    if (coursesInfo?.length && userData) {
      setIsLoading(true);
      updateModuleCollection(`course_${courseId}`, `module_${getIdForNewModule()}`, `info`, updateCourseData, {
        info: {
          id: getIdForNewModule(),
          name: 'Новый модуль',
          createdAt: getCurrentTimeInMoscow(),
          updatedAt: getCurrentTimeInMoscow(),
          iconURL: Logo,
          status: 'доступно',
          lectureNames: [],
          disabled: false,
          lectureCount: 0,
        }
      })
    }
  }

  const onSaveHandler = () => {
    setIsLoading(true);
    updateCourseCollection(`course_${courseId}`, successUpdateCallback, {
      info: {
        promoCode: courseParams.promoCode,
        name: courseParams.name,
        description: courseParams.description,
        iconURL: courseParams.iconURL,
        passingTime: courseParams.passingTime,
        updatedAt: getCurrentTimeInMoscow(),
      }
    })
  }

  const onDeleteHandler = () => {
    if (coursesInfo[courseId].moduleNames.length) {
      alert('Ошибка: Необходимо удалить все модули')
    } else {
      setIsLoading(true);
      deleteCourseCollection(`course_${courseId}`, () => { successUpdateCallback().then(r => navigate('/teacher')); });
    }
  }

  useEffect(() => {
    if (coursesInfo?.length) {
      setCourseParams(coursesInfo.find(el => el.id === +courseId));
    }
  }, [coursesInfo])

  return (
    <ContentWrapper>
      <MainPageTitle>Редактор курса</MainPageTitle>

      <Grid style={{ maxWidth: '1190px', margin: 'auto' }} container spacing={2} marginBottom={10}>
        {
          coursesInfo?.length && !_.isEmpty(courseParams) ? (
            <Grid item xs={12}>
              <Card
                sx={{ maxWidth: '100%', marginBottom: '20px' }}
              >
                <div style={{ display: 'flex' }}>
                  <CardMediaMobile
                    component="img"
                    sx={{ width: '268px', padding: '16px', borderRadius: '20px', height: '194px' }}
                    image={courseParams.iconURL}
                  />
                  <CardContent sx={{ flex: '1' }}>
                    <TypographyMobile gutterBottom variant="h6" component="div" fontSize="22px" mobileSize={1}>
                      {courseParams.name}
                    </TypographyMobile>
                    <TypographyMobile mobileSize={0.8} sx={{ marginTop: '6px'}} variant="body2" color="text.secondary" fontSize="14px" height="52px">
                      {courseParams.description}
                    </TypographyMobile>
                    <AwardStats>
                      <CustomBadge sx={{ marginRight: '8px' }} message="Награда:" colorType={0} small />
                      <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде опыта">
                        <DetailsBox style={{ marginRight: '6px' }}>
                          <GamePointsBadge
                            count={`+${getMaxCourseAward(getFullLessonCountInCourse(courseData[`course_${courseId}`]), getFullModuleCountInCourse(courseData[`course_${courseId}`]))[0]}`}
                            pointType="1" small rectangular/>
                        </DetailsBox>
                      </Tooltip>

                      <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде GreenCoin">
                        <DetailsBox>
                          <GamePointsBadge
                            count={`+${getMaxCourseAward(getFullLessonCountInCourse(courseData[`course_${courseId}`]), getFullModuleCountInCourse(courseData[`course_${courseId}`]))[1]}`}
                            pointType="0" small rectangular/>
                        </DetailsBox>
                      </Tooltip>
                    </AwardStats>

                    <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'end' }}>
                      <Tooltip style={{ marginRight: '30px' }} TransitionComponent={Zoom} placement="top" arrow title="Продолжительность курса">
                        <DetailsBox>
                          <TimelapseIcon sx={{ marginRight: '4px', width: '20px' }} /> {courseParams?.passingTime}
                        </DetailsBox>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Дата последнего изменения">
                        <DetailsBox>
                          <EventNoteIcon sx={{ marginRight: '4px', width: '20px' }} /> Изменен: {getLocalTimeFromUnixTime(courseParams.updatedAt)}
                        </DetailsBox>
                      </Tooltip>
                    </div>
                  </CardContent>
                </div>
              </Card>
              <TextField
                fullWidth
                id="outlined-basic"
                value={courseParams.name}
                variant="outlined"
                onChange={(e) => {
                  setCourseParams(prev => ({ ...prev, name: e.target.value }));
                }}
                style={{ marginBottom: '20px' }}
                label="Название курса"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                value={courseParams.iconURL}
                variant="outlined"
                onChange={(e) => {
                  setCourseParams(prev => ({ ...prev, iconURL: e.target.value }));
                }}
                style={{ marginBottom: '20px' }}
                label="Ссылка на лого курса"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                value={courseParams.description}
                variant="outlined"
                onChange={(e) => {
                  setCourseParams(prev => ({ ...prev, description: e.target.value }));
                }}
                style={{ marginBottom: '20px' }}
                label="Описание курса"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                value={courseParams.promoCode}
                variant="outlined"
                onChange={(e) => {
                  setCourseParams(prev => ({ ...prev, promoCode: e.target.value }));
                }}
                style={{ marginBottom: '20px' }}
                label="Промокод для регистрации учеников"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                value={courseParams.passingTime}
                variant="outlined"
                onChange={(e) => {
                  setCourseParams(prev => ({ ...prev, passingTime: e.target.value }));
                }}
                style={{ marginBottom: '20px' }}
                label="Продолжительность курса"
              />
              <TeacherOptionLine onSaveHandler={onSaveHandler} onDeleteHandler={onDeleteHandler}/>

              <TypographyMobile mobileSize={1.4} sx={{margin: 4, textAlign: 'center'}} variant="h5" color="text.secondary">Модули</TypographyMobile>
              {
                coursesInfo?.length && courseParams ? (
                  courseParams?.moduleNames?.map((name, index) => (
                    <Grid item xs={12} key={index} style={{ marginTop: '16px' }}>
                      <Link to={`/teacher/course/module?courseId=${courseId}&moduleId=${courseParams?.moduleIds[index]}`} key={index}>
                        <CardWrapper moduleName={name} img="" lectureCount={courseParams?.lectureCount[index]} index={index} rightCard={index % 2 !== 0} />
                      </Link>
                    </Grid>
                  ))) : null
              }
              <Grid item xs={12} key="add-new-course" style={{ marginTop: '16px' }}>
                <AlertDialogSlide
                  mainBtnText="+"
                  mainBtnStyle={AddBtnStyle}
                  dialogTitle="Уверены, что хотите создать новый модуль?"
                  dialogDescription = "Создание модуля может занять некторое время"
                  onAgreeHandler={() => createModuleHandler()}
                />
              </Grid>
            </Grid>
          ) : <Loader />
        }
      </Grid>

      { isLoading && <LoaderWithOverlay /> }
    </ContentWrapper>
  )
}

export default CoursePanel;