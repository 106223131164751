import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import PageContent from "../../containers/PageContent";
import {hoverColor, mainColor} from "../../constants/colors";
import {secondColor} from "../../constants/colors";
import {Button, Typography} from "@mui/material";
import MainPageTitle from "../../containers/MainPageTitle";
import {
  saveUsersAward, setDoneSectionCells, setEmptyPageCells, updateLectureProgress, updateQuizProgress,
} from "../../utils/services/learnPageService";
import Loader from "../Loader";
import {useLecturesProgress} from "../../utils/services/сalculationService/courseProgress";
import {useSearchParams} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector} from "react-redux";
import {setCourseData, setFreelanceData, setUserData} from "../../utils/reducers/repoReducer";
import {
  findPlatformTrainingStep, getItemById, giveUserAwards, updatePlatformTrainingHandler
} from "../../utils/services";
import {serviceEconomics} from "../../utils/services/ServiceEconomics";
import techWorkImg from "../../media/techWork.png";
import {SideNavigation} from "../SideNavigation";
import {getModuleDataFromDB} from "../../utils/services/firestoreHandlers";
import _ from "lodash";

const SectionsWrapper = styled('div')`
  background: ${mainColor};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  border-radius: 8px 0 0 8px;
  z-index: 200;
  color: white;
  overflow-y: auto;
  padding-bottom: 40px;
  
  &::-webkit-scrollbar {
    width: 0.4em;
    background-color: #C4C4C4;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  };
  &::-webkit-scrollbar-thumb {
    border: 1px solid rgba(136, 136, 136, 0.5);
    border-radius: 20px;
  }
`

const SectionTitle = styled('h1')`
  text-align: center;
  font-size: 20px;
  padding: 12px;
  margin: 20px 0;
  
  @media (max-width: 430px) {
    margin: 50px 0 20px 0;
    font-size: 0.8rem;
  }
`

const SectionBurgerButton = styled(`Button`)`
  position: fixed;
  right: 20px;
  top: 12px;
  z-index: 300;
  border: none;
  background: none;
  color: ${props => props.showSectionTab ? 'white' : mainColor}}
`;

const Divider = styled('hr')`
  background-color: #d7d9da;
  height: 1px;
  border: none;
  margin: 0;
`
const ContentWrapper = styled('div')`
  padding-right: 320px;
  
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`
const ButtonWrapper = styled('div')`
  margin-top: 20px;
  text-align: center;

  ${props => props.pulseHighlight ? `
    & button::before {
      content: '';
      border-radius: 1000px;
      border: 6px solid #ffc107;
      box-shadow: 0 0 60px #ffc107;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: ellipse3 2s infinite;
    }
    & button {
      position: relative;
      z-index: 100;
    }
  
    @keyframes ellipse3 {
      0% {
        width: 30px;
        height: 30px;
        opacity: 1;
      }
      100% {
        width: 200px;
        height: 100px;
        opacity: 0;
      }
    }
  ` : null}
`
const TechWorkImg = styled('img')`
  width: 170px;
  margin: 100px auto 20px auto;
`;

const StudyPlatform = () => {
  const {innerWidth: width} = window;
  const dispatch = useDispatch()
  const courseData = useSelector(state => state.repos.courseData)
  const userData = useSelector(state => state.repos.userData)
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const moduleData = useSelector(state => state.repos.moduleData);
  const [moduleInfo, setModuleInfo] = useState({});
  const [lectureList, setLectureList] = useState({});

  const [urlParameters, setUrlParameters] = useSearchParams();
  const courseId = +urlParameters.get('courseId');
  const moduleId = +urlParameters.get('moduleId');

  const lessonData = useLecturesProgress(courseData, courseId, moduleId)
  const [isUserAuthorized, setIsUserAuthorized] = useState(JSON.parse(localStorage.getItem('st_user_authorized')))
  const [currentSectionId, setCurrentSectionId] = useState(0)
  const [currentPageId, setCurrentPageId] = useState(0)
  const [donePage, setDonePage] = useState(setEmptyPageCells(lectureList))
  const [showSectionTab, setShowSectionTab] = useState(false)
  const isDisabledEndButton = useMemo(() => lessonData[currentSectionId]?.length && Number(lessonData[currentSectionId][currentPageId]), [currentPageId, currentSectionId, lessonData])
  const doneSections = useMemo(() => setDoneSectionCells(lessonData), [lessonData])

  let awardBtnDisabled = useMemo(() => courseData[`course_${courseId}`] ? courseData[`course_${courseId}`][`modules`][moduleId][`lectures`]?.[currentSectionId]?.isAwardReceived : null, [courseData, currentPageId]);
  let lectureIsAvailable = useMemo(() => courseData[`course_${courseId}`] ? courseData[`course_${courseId}`][`modules`][moduleId][`lectures`]?.[currentSectionId]?.lectureAvailable : null, [courseData, currentPageId, currentSectionId]);

  const getSectionBtnDisable = (index) => {
    if (courseData[`course_${courseId}`]) {
      return !courseData[`course_${courseId}`][`modules`][moduleId][`lectures`][index]?.lectureAvailable
    }
  }

  const pageBtnHandler = (pageId) => {
    setCurrentPageId(pageId)
  }

  const getAwardRatio = () => {
    return [serviceEconomics().moduleControlTestDone.exp, serviceEconomics().moduleControlTestDone.greenCoin]
  }

  const openSectionTab = () => setShowSectionTab(prevState => !prevState)

  const setCourseDataHandler = payload => {
    dispatch(setCourseData(payload))
  }

  const setUserDataHandler = payload => {
    dispatch(setUserData(payload))
  }

  const completedPageHandler = async () => {
    await saveUserAwardHandler(serviceEconomics().lectureDone.greenCoin, serviceEconomics().lectureDone.exp);
    await updateLectureProgress(isUserAuthorized, courseId, moduleId, currentSectionId, currentPageId, courseData, setCourseDataHandler);
  }

  const updateTestProgressHandler = async (userAnswers) => await updateQuizProgress(isUserAuthorized, userAnswers, courseId, moduleId, currentSectionId, currentPageId, courseData, setCourseDataHandler)

  const saveUserAwardHandler = async (greenCoinCount, expCount, goldCoinCount) => {
    await saveUsersAward(isUserAuthorized, userData, setUserDataHandler, greenCoinCount, expCount, goldCoinCount, courseId, moduleId, currentSectionId, setCourseDataHandler)
  }

  const setCompletedStateForPageButton = (index) => {
    let state = 'null'
    if(lessonData[currentSectionId]?.[index] === '1'){
      state = 'done'
    }
    return state
  }

  const isLectureDone = () => lessonData[currentSectionId].slice(0, lectureList[currentSectionId].pageCount).find(page => page !== '0')

  // useEffect(() => {
  //   const taskIsAvailable = freelanceData[`course_${courseId}`] ? freelanceData[`course_${courseId}`].modules[moduleId].lectures[currentSectionId]['task_0'].taskAvailable : true;
  //   if(!taskIsAvailable && isLectureDone() && lectureList[currentSectionId].pageType[currentPageId] !== 'К'){
  //     console.log('задачи получена')
  //     const updatedFreelanceData = updateFreelanceAvailableTasks(isUserAuthorized, courseId, moduleId, currentSectionId, dispatch, setFreelanceData);
  //
  //   }
  // }, [courseData, freelanceData, currentSectionId])

  useEffect(() => {
    if (coursesInfo?.length) {
      //Loading data
      getModuleDataFromDB(courseId, getItemById(coursesInfo, courseId)?.moduleIds[moduleId], dispatch).then(r => {});
    }
  }, [coursesInfo])

  useEffect(() => {
    if(!_.isEmpty(moduleData)) {
      //Saving data in redux
      setModuleInfo(moduleData[0].info);
      setLectureList(moduleData.slice(1, moduleData.length).sort((a, b) => a.id - b.id));
    }
  }, [moduleData])

  return(
      <>
        {
          width <= 500 ?
            <SectionBurgerButton onClick={openSectionTab} showSectionTab={showSectionTab}>
              <MenuIcon/>
            </SectionBurgerButton>
            : null
        }
        {
          width >= 500 || showSectionTab ?
            <SectionsWrapper>
              <SectionTitle>{getItemById(coursesInfo, courseId)?.name}</SectionTitle>
              <Divider />
              <SideNavigation
                sectionTitles={moduleInfo?.lectureNames}
                isActiveSection={(sectionId) => sectionId === currentSectionId}
                isSectionDone={(id) => doneSections[id]}
                onClickHandler={(sectionId) => setCurrentSectionId(sectionId)}
                disableHandler={(id) => getSectionBtnDisable(id)}
              />
            </SectionsWrapper>
            : null
        }
        <ContentWrapper>
          {
            lessonData.length && !_.isEmpty(lectureList) ?
              <>
                <MainPageTitle>{lectureList[currentSectionId]?.sectionName}</MainPageTitle>
                <Divider />
                      {
                        Object.keys(lectureList[currentSectionId].pageFlow).length > 1 ?
                          <div>
                            {
                              lectureIsAvailable ?
                                <PageContent pageData={lectureList[currentSectionId].pageFlow} currentPageId={currentPageId} currentSectionId={currentSectionId} /> :
                                <div>
                                  <TechWorkImg src={techWorkImg} alt=""/>
                                  <Typography variant="h5" textAlign="center">
                                    Раздел временно недоступен
                                  </Typography>
                                </div>
                            }
                          </div>
                          : null
                      }
                      {
                        lectureIsAvailable ?
                          <>
                            {
                              lessonData[currentSectionId].slice(0, lectureList[currentSectionId].pageCount).filter(el => el === '0').length === 1 ?
                                <ButtonWrapper pulseHighlight={findPlatformTrainingStep(userData)?.index === 0}>
                                  {
                                    giveUserAwards(
                                    isDisabledEndButton,
                                    completedPageHandler,
                                    serviceEconomics().lectureDone.greenCoin,
                                    serviceEconomics().lectureDone.exp,
                                    )
                                  }
                                </ButtonWrapper>
                                   :
                                <ButtonWrapper pulseHighlight={findPlatformTrainingStep(userData)?.index === 0}>
                                  <Button
                                    variant="contained"
                                    color='success'
                                    disabled={isDisabledEndButton}
                                    onClick={completedPageHandler}
                                  >
                                    Завершить
                                  </Button>
                                </ButtonWrapper>
                            }
                          </>
                          : null
                      }
              </>
            :
              <Loader />
          }
        </ContentWrapper>
      </>
  )
}

export default StudyPlatform