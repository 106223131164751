import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import GamePointsBadge from "../component/GamePointsBadge";
import {serviceEconomics} from "../utils/services/ServiceEconomics";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import React from "react";
import styled from "styled-components";
import successIcon from './success_check.png';
import toExecuteIcon from './toExecution_check.png';
import {useSelector} from "react-redux";

const InteractiveCard = styled(Card)`
  position: relative;
  ${props => props.isAvailable ? `
    &:hover{
      transition: 0.5s;
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
      cursor: pointer;
    }
  ` : `
    filter: grayscale(1);
    opacity: 0.5;
  `}
`;

const AwardStats = styled('div')`
  display: flex;

  @media (max-width: 430px) {
    padding: 8px;
  }
`;

const DetailsBox = styled('span')`
  display: flex;
  align-items: center;
  & span {
    background: none;
    font-size: 12px!important;
    font-weight: 500!important;
  }
`;

const CardIcon = styled.div`
  background-image: url(${props => props.img || null});
  width: 80px;
  height: 80px;
  background-size: 120%;
  background-position: center;
  border-radius: 50%;
  flex: 1 0 auto;
  margin-right: 20px;
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

const CardContainer = styled(CardContent)`
  display: flex;
  align-items: center;
  padding: 6px 16px 0 16px;
  
  @media (max-width: 430px) {
    padding: 10px 10px!important;
  }
`;

const SuccessIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const CardDescription = styled.div`
  margin-right: 10px;
`;

const FlatCard = ({ elem, externalData, isAvailable, isCompleted }) => {
  const coursesInfo = useSelector(state => state.repos.coursesInfo)

  return (
    <InteractiveCard isAvailable={isAvailable}>
      <CardContainer>
        <CardIcon img={coursesInfo?.length ? coursesInfo[elem.courseId].iconURL : ""} />
        <CardDescription>
          <TypographyMobile gutterBottom component="div" fontSize='0.8rem' fontWeight="600" mobileSize={0.8}>
            { externalData && externalData.mainTitle.length > 40 ? `${externalData.mainTitle.slice(0, 40)}...` : externalData.mainTitle}
          </TypographyMobile>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <AwardStats>
              <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде опыта">
                <DetailsBox style={{marginRight: '6px'}}>
                  <GamePointsBadge count={`+${elem.data.difficult === 0 ? serviceEconomics().easyFreelanceTaskDone.exp : serviceEconomics().hardFreelanceTaskDone.exp}`} pointType="1" small rectangular outlined/>
                </DetailsBox>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде GreenCoin">
                <DetailsBox>
                  <GamePointsBadge count={`+${elem.data.difficult === 0 ? serviceEconomics().easyFreelanceTaskDone.greenCoin : serviceEconomics().hardFreelanceTaskDone.greenCoin}`} pointType="0" small rectangular outlined/>
                </DetailsBox>
              </Tooltip>
            </AwardStats>
          </div>
          <TypographyMobile sx={{marginTop: '6px'}} variant="body2" color="text.secondary" fontSize="14px" mobileSize={0.6}>
            {externalData.description.length > 80 ? `${externalData.description.slice(0, 80)}...` : externalData.mainTitle}
          </TypographyMobile>
        </CardDescription>
        <SuccessIcon src={isCompleted ? successIcon : toExecuteIcon} alt=""/>
      </CardContainer>
    </InteractiveCard>
  )
}

export default FlatCard;