import FrontEnd_1_1 from "../../../externalData/Target/FrontEnd_1/FrontEnd_s1.json"
import FrontEnd_1_2 from "../../../externalData/Target/FrontEnd_1/FrontEnd_s2.json"
import FrontEnd_1_3 from "../../../externalData/Target/FrontEnd_1/FrontEnd_s3.json"
import FrontEnd_1_4 from "../../../externalData/Target/FrontEnd_1/FrontEnd_s4.json"

import FrontEnd_2_1 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s1.json"
import FrontEnd_2_2 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s2.json"
import FrontEnd_2_3 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s3.json"
import FrontEnd_2_4 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s4.json"
import FrontEnd_2_5 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s5.json"
import FrontEnd_2_6 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s6.json"
import FrontEnd_2_7 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s7.json"
import FrontEnd_2_8 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s8.json"
import FrontEnd_2_9 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s9.json"
import FrontEnd_2_10 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s10.json"
import FrontEnd_2_11 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s11.json"
import FrontEnd_2_12 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s12.json"
import FrontEnd_2_13 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s13.json"
import FrontEnd_2_14 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s14.json"
import FrontEnd_2_15 from "../../../externalData/Target/FrontEnd_2/FrontEnd_s15.json"

import FrontEnd_3_1 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s1.json"
import FrontEnd_3_2 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s2.json"
import FrontEnd_3_3 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s3.json"
import FrontEnd_3_4 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s4.json"
import FrontEnd_3_5 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s5.json"
import FrontEnd_3_6 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s6.json"
import FrontEnd_3_7 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s7.json"
import FrontEnd_3_8 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s8.json"
import FrontEnd_3_9 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s9.json"
import FrontEnd_3_10 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s10.json"
import FrontEnd_3_11 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s11.json"
import FrontEnd_3_12 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s12.json"
import FrontEnd_3_13 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s13.json"
import FrontEnd_3_14 from "../../../externalData/Target/FrontEnd_3/FrontEnd_s14.json"

import FrontEnd_4_1 from "../../../externalData/Target/FrontEnd_4/FrontEnd_s1.json"
import FrontEnd_4_2 from "../../../externalData/Target/FrontEnd_4/FrontEnd_s2.json"
import FrontEnd_4_3 from "../../../externalData/Target/FrontEnd_4/FrontEnd_s3.json"
import FrontEnd_4_4 from "../../../externalData/Target/FrontEnd_4/FrontEnd_s4.json"
import FrontEnd_4_5 from "../../../externalData/Target/FrontEnd_4/FrontEnd_s5.json"

import FrontEnd_5_1 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s1.json"
import FrontEnd_5_2 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s2.json"
import FrontEnd_5_3 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s3.json"
import FrontEnd_5_4 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s4.json"
import FrontEnd_5_5 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s5.json"
import FrontEnd_5_6 from "../../../externalData/Target/FrontEnd_5/FrontEnd_s6.json"

import FrontEnd_6_1 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s1.json"
import FrontEnd_6_2 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s2.json"
import FrontEnd_6_3 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s3.json"
import FrontEnd_6_4 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s4.json"
import FrontEnd_6_5 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s5.json"
import FrontEnd_6_6 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s6.json"
import FrontEnd_6_7 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s7.json"
import FrontEnd_6_8 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s8.json"
import FrontEnd_6_9 from "../../../externalData/Target/FrontEnd_6/FrontEnd_s9.json"

import FrontEnd_7_1 from "../../../externalData/Target/FrontEnd_7/FrontEnd_s1.json"

import FrontEnd_8_1 from "../../../externalData/Target/FrontEnd_8/FrontEnd_s1.json"
import FrontEnd_8_2 from "../../../externalData/Target/FrontEnd_8/FrontEnd_s2.json"

export const targetMaterials = () => (
  [
    [
      FrontEnd_1_1, FrontEnd_1_2, FrontEnd_1_3, FrontEnd_1_4
    ],
    [
      FrontEnd_2_1, FrontEnd_2_2, FrontEnd_2_3, FrontEnd_2_4, FrontEnd_2_5, FrontEnd_2_6, FrontEnd_2_7, FrontEnd_2_8,
      FrontEnd_2_9, FrontEnd_2_10, FrontEnd_2_11, FrontEnd_2_12, FrontEnd_2_13, FrontEnd_2_14, FrontEnd_2_15
    ],
    [
      FrontEnd_3_1, FrontEnd_3_2, FrontEnd_3_3, FrontEnd_3_4, FrontEnd_3_5, FrontEnd_3_6, FrontEnd_3_7, FrontEnd_3_8,
      FrontEnd_3_9, FrontEnd_3_10, FrontEnd_3_11, FrontEnd_3_12, FrontEnd_3_13, FrontEnd_3_14
    ],
    [
      FrontEnd_4_1, FrontEnd_4_2, FrontEnd_4_3, FrontEnd_4_4, FrontEnd_4_5
    ],
    [
      FrontEnd_5_1, FrontEnd_5_2, FrontEnd_5_3, FrontEnd_5_4, FrontEnd_5_5, FrontEnd_5_6
    ],
    [
      FrontEnd_6_1, FrontEnd_6_2, FrontEnd_6_3, FrontEnd_6_4, FrontEnd_6_5, FrontEnd_6_6, FrontEnd_6_7, FrontEnd_6_8,
      FrontEnd_6_9
    ],
    [
      FrontEnd_7_1
    ],
    [
      FrontEnd_8_1, FrontEnd_8_2
    ]
  ]
)





