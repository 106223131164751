import Snackbar from "../../component/Shackbar";
import goldCoinImg from "../../media/GoldCoin.png";
import greenCoinImg from "../../media/GreenCoin.png"
import experienceImg from "../../media/experience.png";
import React from "react";
import teacherData from "../../component/Header/Navbar/teacherData.json";
import {useSnackbar} from "notistack";
import styled from "styled-components";

import PlatformTrainingImg1 from '../../media/platformTrain1.png';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {setUserData, setUsersAuthData} from "../reducers/repoReducer";
import {useSelector} from "react-redux";
import {ROLES_LIST} from "../../constants/constants";

const SnackbarButton = styled('a')`
  border: 1px solid;
  border-radius: 6px;
  padding: 8px;
  background: none;
  color: white;
  font-size: 14px;
  &:hover{
    cursor: pointer;
  }
`;

export const giveUserAwards = (awardBtnDisabled, saveUserAwardHandler, greenCoin, experience, style, goldCoin) => {
  const awardsType = [Math.floor(greenCoin), Math.floor(experience), goldCoin];
  const awardIcons = [greenCoinImg, experienceImg, goldCoinImg];
  return(
    <Snackbar
      style={style}
      awardsType={awardsType}
      rightIcon={awardIcons}
      saveUserAwardHandler={saveUserAwardHandler}
      awardBtnDisabled={awardBtnDisabled}
    />
  )
}

export const isTeacherAccount = () => (
  teacherData.teacherHash === JSON.parse(localStorage.getItem('st_user_authorized'))?.uid
)

export function UnAuthorizedSnackbarBox() {
  const { enqueueSnackbar } = useSnackbar();
  const action = () => <SnackbarButton href="https://studopolis-school.ru/" > Открыть</SnackbarButton>;

  enqueueSnackbar('Доступ к платформе StuDopolis', { action,
    persist: true,
    variant: 'success',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    }}
  );
  return <></>
}

export const instructionsToUnlockSections = ([
  {
    title: 'Награда за каждое занятие? Конечно!',
    description: 'Изучай теорию, выполняй домашние задания и получай в награду GCoin и опыт!',
    action: 'Нажми "Завершить", чтобы открыть доступ к Личному кабинету',
    img: PlatformTrainingImg1
  },
  {
    title: 'Зарплату в студию!',
    description: 'Заходи каждый день на Studopolis и забирай зарплату в зависимости от твоей карьерной позиции.',
    action: 'Нажми "Получить награду" и открой доступ к G-Pay',
    img: ''
  },
  {
    title: 'Изучай, зарабатывай и покупай!',
    description: 'В разделе G-Pay ты можешь покупать за GCoin курсы, ассеты и другие полезные материалы!',
    action: 'Нажми "Купить", чтобы открыть доступ к Фрилансу',
    img: ''
  },
  {
    title: 'Фриланс задачи всем и каждому!',
    description: 'Получай фриланс задачи после каждого занятия! Фриланс задачи позволят получить дополнительные GCoin и опыт.',
    action: 'Заверши первую фриланс задачу и открой доступ к Рейтингу',
    img: ''
  },
  {
    title: 'Соревнование - это наше все!',
    description: 'Соревнуйся с другими учениками и поднимайся в топы по рейтингам!',
    action: 'Переходи в раздел рейтинга, чтобы открыть доступ к Новостям',
    img: ''
  },
  {
    title: 'Ни дня без развития!',
    description: 'В разделе Новости ты найдешь много полезных статей, фактов и прочее. Узнавай новое каждый день. Вперед за знаниями!',
    action: '',
    img: ''
  },
]);

export const platformTrainingSequence = [
  { step: 'isFirstLectureDone', navEl: 'courses', index: 0 },
  { step: 'isFirstSalaryReceived', navEl: '', index: 1 },
  { step: 'isFirstGPayBought', navEl: 'shop', index: 2 },
  { step: 'isFirstFreelanceTaskDone', navEl: 'freelance', index: 3 },
  { step: 'isOpenRating', navEl: 'rating', index: 4 },
  { step: 'isOpenNews', navEl: 'news', index: 5 },
]

export const updatePlatformTrainingHandler = async (isUserAuthorized, userData, trainNumber, dispatch) => {

  const userDocRef = doc(db, "users", isUserAuthorized.uid);

  await updateDoc(userDocRef, {
    [`platformTraining.${platformTrainingSequence[trainNumber].step}`]: true,
  });
  dispatch(setUserData({...userData, platformTraining: { ...userData.platformTraining, [platformTrainingSequence[trainNumber].step]: true }}))
};

export const findPlatformTrainingStep = (userData) => platformTrainingSequence.find(el => !userData?.platformTraining?.[el.step] ? el : null);

export const findMyApartment = (userData, shopData) => {
  if (shopData.items && userData){
    const apartments = shopData?.items.filter(el => el.tag === 'apartment');
    return userData.purchasedApartment ? apartments.find(el => el.name === userData.purchasedApartment) : apartments[0];
  }
  return null;
}

export const itHasPermissions = (userRole, elementPermission) => {
  if (userRole === ROLES_LIST.ADMIN
    && (elementPermission === ROLES_LIST.ADMIN
      || elementPermission === ROLES_LIST.STUDENT)
  ) return true;

  if (userRole === ROLES_LIST.MODERATOR
    && (elementPermission === ROLES_LIST.MODERATOR
      || elementPermission === ROLES_LIST.STUDENT)
  ) return true;

  if (userRole === ROLES_LIST.TEACHER
    && (elementPermission === ROLES_LIST.TEACHER
      || elementPermission === ROLES_LIST.STUDENT)
  ) return true;

  return userRole === ROLES_LIST.STUDENT && elementPermission === ROLES_LIST.STUDENT;
}

export const getCurrentTimeInMoscow = () => {
  const currentTime = new Date();
  // Устанавливаем часовой пояс Москвы (GMT+3)
  currentTime.setHours(currentTime.getUTCHours() + 3);
  return currentTime.getTime();
}

export const getLocalTimeFromUnixTime = (unixTimestamp) => {
  const date = new Date(unixTimestamp); // Ваш таймстамп в миллисекундах

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes} | ${day}.${month}.${year}`;
}

export const getItemById = (obj, id) => obj.find((el, index) => el.id === +id)
