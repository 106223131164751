import frontend_icon from "../media/courseWallpaper/frontend.png";
import minecraft_icon from "../media/courseWallpaper/minecraft.png";
import python_icon from "../media/courseWallpaper/python.png";
import figma_icon from "../media/courseWallpaper/figma.png";
import unity_icon from "../media/courseWallpaper/unity.png";
import adobe_illustrator_icon from "../media/courseWallpaper/adobe_illustrator.png";
import blender_icon from "../media/courseWallpaper/blender.png";
import unreal_engine_icon from "../media/courseWallpaper/unreal_engine.png";
import ksp_icon from "../media/courseWallpaper/kerbal_space_programm.png";
import logo from "../component/Header/Navbar/media/logo.png";
import learn_newImg from "../component/Header/Navbar/media/learn_new.png";
import freelanceImg from "../component/Header/Navbar/media/freelance_new.png";
import ratingImg from "../component/Header/Navbar/media/rating_new.png";
import unity_3Days_intensive from "../media/courseWallpaper/unity_3Days_intensive.png";
import targetImg from "../media/courseWallpaper/target.png";
import mentoringByKanatBaktybekImg from "../media/courseWallpaper/Mentoring-by-Kanat-Baktybek.png";
import campImg from "../component/Header/Navbar/media/camp_new.png";
import companyImg from "../component/Header/Navbar/media/company_new.png";
import html_bg from "../media/html_background.png";
import messageImg from "../component/Header/Navbar/media/message_new.png";
import css_bg from "../media/css_background.png";
import js_bg from "../media/js_background.png";
import react_bg from "../media/react_background.png";
import redux_bg from "../media/redux_background.png";
import newsImg from "../component/Header/Navbar/media/news_new.png";
import admin from "../component/Header/Navbar/media/admin_logo_important.png";
import shop from "../component/Header/Navbar/media/bitcoin.png";
import cSharp_unity from "../media/csharp_module.png";
import unity_first_unity from "../media/unity_1_module.png";
import unity_second_unity from "../media/unity_module.png";
import {hoverColor, inWorkColor, secondColor, successColor} from "../constants/colors";
import item_5 from "../media/platformer2D_screen.png";
import item_3 from "../media/apk.png";
import item_1 from "../media/csharp_module.png";
import item_2 from "../media/asset.png";
import item_4 from "../media/unity_ai.png";

import flat_default from '../media/apartments/flat_default.jpg';
import flat_economy_1 from '../media/apartments/flat_economy_1.jpg';
import flat_economy_2 from '../media/apartments/flat_economy_2.jpg';
import flat_standard_1 from '../media/apartments/flat_standard_1.jpg';
import flat_standard_2 from '../media/apartments/flat_standard_2.jpg';
import flat_premium_1 from '../media/apartments/flat_premium_1.jpg';
import flat_lux_1 from '../media/apartments/flat_lux_1.jpg';
import flat_lux_2 from '../media/apartments/flat_lux_2.jpg';
import flat_lux_3 from '../media/apartments/flat_lux_3.jpg';
import {ROLES_LIST} from "../constants/constants";

const COURSE_AVAILABLE = { title: 'доступно', color: inWorkColor };
const COURSE_DONE = { title: 'готово', color: successColor };
const COURSE_ONLY_CAMP = { title: 'лагерь', color: secondColor };
const COURSE_SOON = { title: 'скоро', color: hoverColor };

export const careersRang = [
  {vacancy: 'Стажер', requiredExp: '-', rewardGoldCoin: '-', rewardGreenCoin: '2000' },
  {vacancy: 'Младший разработчик (Junior)', requiredExp: '55000', rewardGoldCoin: '250', rewardGreenCoin: '5000' },
  {vacancy: 'Разработчик (Middle)', requiredExp: '100000', rewardGoldCoin: '400', rewardGreenCoin: '20000' },
  {vacancy: 'Опытный разработчик (Senior)', requiredExp: '170000', rewardGoldCoin: '650', rewardGreenCoin: '35000' },
  {vacancy: 'Ведущий разработчик (TeamLead)', requiredExp: '260000', rewardGoldCoin: '950', rewardGreenCoin: '45000' },
]

export const coursesData = [
  { name: 'Разработка 2D платформера на Unity за 3 дня',
    iconURL: unity_3Days_intensive,
    description: `Обучение основам Unity и сборка игры`,
    passingTime: '3 дня',
    status: COURSE_AVAILABLE,
    modulesName: ["Разработка 2D платформера"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [3],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: false,
  },
  { name: 'Программирование на C#',
    iconURL: cSharp_unity,
    description: `Обучение основам языка программирования С#`,
    passingTime: '3 недели',
    status: COURSE_AVAILABLE,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: false,
  },
  { name: 'Разработка игр на Unity',
    iconURL: unity_icon,
    description: `Обучение созданию собственных игр на движке Unity`,
    passingTime: '3 недели',
    status: COURSE_ONLY_CAMP,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Разработка сайтов',
    iconURL: frontend_icon,
    description: `Разработка современных веб-сайтов на Javascript, React`,
    passingTime: '4,5 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Программирование на Minecraft',
    iconURL: minecraft_icon,
    description: `Обучение программированию на базе Minecraft Education`,
    passingTime: '3 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Программирование на Python',
    iconURL: python_icon,
    description: `Обучение программированию на языке Python`,
    passingTime: '3 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Веб-дизайн',
    iconURL: figma_icon,
    description: `Обучение созданию красочных дизайнов сайтов`,
    passingTime: '3,5 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Цифровой рисунок (Digital Art)',
    iconURL: adobe_illustrator_icon,
    description: `Обучение работы с adobe illustrator и анимации`,
    passingTime: '4 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: '3D моделирование',
    iconURL: blender_icon,
    description: `Создание собственных трехмерных объектов в Blender`,
    passingTime: '3 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Разработка игр на Unreal Engine',
    iconURL: unreal_engine_icon,
    description: `Разработка игр на языке С++`,
    passingTime: '3,5 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Космо-проектирование KSP',
    iconURL: ksp_icon,
    description: `Обучение конструированию ракет, запусков на дальние орбиты и построение траектории`,
    passingTime: '2 месяца',
    status: COURSE_SOON,
    modulesName: ["Основы C#", "Продвинутая работа с методами", "Основы ООП"],
    moduleImg: ["html_background.png", "css_background.png", "js_background.png"],
    lectureCount_DB: [9, 6, 9],
    isOpenByDefault: false,
    withControlWorkInModules: false,
    withFreelance: true,
    disabled: true,
  },
  { name: 'Фокус-группа ТАРГЕТ С НУЛЯ',
    iconURL: targetImg,
    description: `Курс для тех, кто хочет научиться запускать таргет с нуля и зарабатывать на этом`,
    passingTime: '60 дней',
    status: COURSE_AVAILABLE,
    modulesName: [
      "Вводная информация",
      "Глубинный анализ",
      "Как создать креатив",
      "Мобилография",
      "Техническая настройка FB и IN",
      "Где взять клиента",
      "Виды блокировок",
      "Анализ работы"
    ],
    moduleImg: [
      "target_background.png",
      "target_background.png",
      "target_background.png",
      "target_background.png",
      "target_background.png",
      "target_background.png",
      "target_background.png",
      "target_background.png"
    ],
    lectureCount_DB: [4, 15, 14, 5, 6, 2, 1, 2],
    isOpenByDefault: false,
    withControlWorkInModules: true,
    withFreelance: false,
    disabled: false,
  },
  { name: 'Канат Бактыбек. Наставничество',
    iconURL: mentoringByKanatBaktybekImg,
    description: `Запусти свой магазин и выйти на стабильные продажи за 60 дней`,
    passingTime: '60 дней',
    status: COURSE_AVAILABLE,
    modulesName: [
      "Модуль 1",
      "Модуль 2",
      "Модуль 3",
      "Модуль 4",
      "Модуль 5",
      "Модуль 6",
      "Модуль 7"
    ],
    moduleImg: [
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png",
      "Mentoring-by-Kanat-Baktybek.png"
    ],
    lectureCount_DB: [8, 9, 7, 4, 3, 5, 5],
    isOpenByDefault: false,
    withControlWorkInModules: true,
    withFreelance: false,
    disabled: false,
  },
]

export const pageNavigationData = [
  {img: logo, msg: false, logo: true, link: '', demoPage: true, role: ROLES_LIST.STUDENT},
  {name: 'Учитель', img: admin, msg: 'teacher', link: 'teacher', demoPage: false, role: ROLES_LIST.TEACHER},
  {name: 'Админ', img: admin, msg: 'moderator', link: 'moderator', demoPage: false, role: ROLES_LIST.MODERATOR},
  {name: 'Админ', img: admin, msg: 'administration', link: 'administration', demoPage: false, role: ROLES_LIST.ADMIN},
  {name: 'Профиль', img: freelanceImg, msg: 'profile', link: 'profile', demoPage: false, role: ROLES_LIST.STUDENT},
  // {name: 'Фриланс', img: learn_newImg, msg: 'freelance', link: 'freelance', demoPage: false, role: ROLES_LIST.STUDENT},
  {name: 'Рейтинг', img: ratingImg, msg: false, link: 'rating', demoPage: false, role: ROLES_LIST.STUDENT},
  {name: 'G-Store', img: shop, msg: false, link: 'shop', demoPage: false, role: ROLES_LIST.STUDENT},
  // {name: 'Лагерь', img: campImg, msg: false, link: 'rating', demoPage: false},
  // {name: 'Модель', img: ratingImg, msg: false, link: 'model', demoPage: false},
  // {name: 'Карьера', img: companyImg, msg: false, link: 'career', demoPage: false},
  // {name: 'Кабинет', img: homeImg, msg: false, link: 'profile', demoPage: false},
  // {name: 'Новости', img: newsImg, msg: false, link: 'news', demoPage: false},
  // {name: 'Почта', img: messageImg, msg: false, link: 'messages', demoPage: false},
]

// export const gStoreItems = [
//   {name: 'Platform2D: Ассет пак для курса по С#', img: item_5, price: 500, isAvailable: true, description: 'Игра порадует классическим ближним боем, это круто...', tag: 'asset', tagName: 'unity ассет' },
//   {name: 'Игра: RocketC', img: item_3, price: 4900, isAvailable: true, description: 'Игра порадует классическим ближним боем, это круто...', tag: 'other', tagName: 'игра'},
//   {name: 'Студия: "Бюджетный Уголок"', img: flat_economy_1, price: 7000, gCoinGain: 100, isAvailable: true, description: 'Просторная эконом квартира, которая готова приносить +100 GCoin каждый день', tag: 'apartment', tagName: 'Эконом'},
//   {name: 'Студия: "Уютная Студия"', img: flat_economy_2, price: 10000, gCoinGain: 150, isAvailable: true, description: 'Еще один вариант эконома, приносит +150 GCoin каждый день', tag: 'apartment', tagName: 'Эконом'},
//   {name: 'Квартира: "Удобное Жилище"', img: flat_standard_1, price: 28000, gCoinGain: 450, isAvailable: true, description: 'Стандартная квартира в стиле этно. Приносит +450 GCoin каждый день', tag: 'apartment', tagName: 'Стандарт'},
//   {name: 'Квартира: "Современный Комфорт"', img: flat_standard_2, price: 33500, gCoinGain: 550, isAvailable: true, description: 'Уютный и просторный стандарт. Может приносить +550 GCoin каждый день', tag: 'apartment', tagName: 'Стандарт'},
//   {name: 'Апартаменты: "Премиум Сьют"', img: flat_premium_1, price: 46000, gCoinGain: 900, isAvailable: true, description: 'Уникальный и приятный премиум. Приносит +900 GCoin каждый день', tag: 'apartment', tagName: 'Премиум'},
//   {name: 'Пентхаус: "Высокий Стиль"', img: flat_lux_1, price: 62500, gCoinGain: 1250, isAvailable: true, description: 'Дорогой люкс. Готов приносить +1250 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
//   {name: 'Пентхаус: "Эксклюзивный Дворец"', img: flat_lux_2, price: 108000, gCoinGain: 1950, isAvailable: true, description: 'Практически личный дворец. Приносит +1950 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
//   {name: 'Пентхаус: "Райская Резиденция"', img: flat_lux_3, price: 210000, gCoinGain: 2450, isAvailable: true, description: 'Уютный, но дорогой люкс. Редкий. Готов приносить +2450 GCoin каждый день', tag: 'apartment', tagName: 'Люкс'},
//   // {name: 'Продвинутый курс по C#', img: item_1, price: 43000, isAvailable: true, description: 'Игра порадует классическим ближним боем, это круто...', tag: 'asset' tagName: 'unity ассет},
//   {name: 'Ассет для 2D игры', img: item_2, price: 9700, isAvailable: false, description: 'Игра порадует классическим ближним боем, это круто...', tag: 'asset', tagName: 'unity ассет'},
//   {name: 'Разработка игр на Unity', img: unity_icon, price: 28800, isAvailable: false, description: 'Обучение созданию собственных игр на движке Unity', tag: 'course', tagName: 'курс'},
//   {name: 'Разработка сайтов', img: frontend_icon, price: 28800, isAvailable: false, description: 'Разработка современных веб-сайтов на Javascript, React', tag: 'course', tagName: 'курс'},
//   {name: 'Программирование на Minecraft', img: minecraft_icon, price: 28800, isAvailable: false, description: 'Обучение программированию на базе Minecraft Education', tag: 'course', tagName: 'курс'},
//   {name: 'Программирование на Python', img: python_icon, price: 28800, isAvailable: false, description: 'Обучение программированию на языке Python', tag: 'course', tagName: 'курс'},
//   {name: 'Веб-дизайн', img: figma_icon, price: 28800, isAvailable: false, description: 'Обучение созданию красочных дизайнов сайтов', tag: 'course', tagName: 'курс'},
//   {name: 'Цифровой рисунок (Digital Art)', img: adobe_illustrator_icon, price: 28800, isAvailable: false, description: 'Обучение работы с adobe illustrator и анимации', tag: 'course', tagName: 'курс'},
//   {name: '3D моделирование', img: blender_icon, price: 28800, isAvailable: false, description: 'Создание собственных трехмерных объектов в Blender', tag: 'course', tagName: 'курс'},
//   {name: 'Разработка игр на Unreal Engine', img: unreal_engine_icon, price: 28800, isAvailable: false, description: 'Разработка игр на Unreal Engine', tag: 'course', tagName: 'курс'},
//   {name: 'Космо-проектирование KSP', img: ksp_icon, price: 28800, isAvailable: false, description: 'Обучение конструированию ракет, запусков на дальние орбиты и построение траектории', tag: 'course', tagName: 'курс'},
// ]