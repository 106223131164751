import Unity3DaysIntensive_Data from '../../../externalData/Unity3DaysIntensive/courseInfo.json'
import CSharp_Data from '../../../externalData/CSharp/courseInfo.json'
import FrontEnd_Data from '../../../externalData/FrontEnd/courseInfo.json'
import DigitalArt_Data from '../../../externalData/DigitalArt/courseInfo.json'
import KerbalSpace_Data from '../../../externalData/KerbalSpace/courseInfo.json'
import Minecraft_Data from '../../../externalData/Minecraft/courseInfo.json'
import Modelling_Data from '../../../externalData/Modelling/courseInfo.json'
import Python_Data from '../../../externalData/Python/courseInfo.json'
import Unity_Data from '../../../externalData/Unity/courseInfo.json'
import UnrealEngine_Data from '../../../externalData/UnrealEngine/courseInfo.json'
import WebDesign_Data from '../../../externalData/WebDesign/courseInfo.json'
import Target_Data from '../../../externalData/Target/courseInfo.json'
import MentoringByKanatBaktybek_Data from '../../../externalData/MentoringByKanatBaktybek/courseInfo.json'
import {materialCollection} from "../../../data/courseData";
import {getCurrentTimeInMoscow} from "../index";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {setAdminCourse, setCourseData} from "../../reducers/repoReducer";


export const createDBArchitecture = () => {
  let courseArchitecture = {}
  const coursesData = [
    Unity3DaysIntensive_Data,
    CSharp_Data,
    FrontEnd_Data,
    DigitalArt_Data,
    KerbalSpace_Data,
    Minecraft_Data,
    Modelling_Data,
    Python_Data,
    Unity_Data,
    UnrealEngine_Data,
    WebDesign_Data,
    Target_Data,
    MentoringByKanatBaktybek_Data
  ];  // Изменять значение в зависимости от количества курсов

  for(let i = 0; i < coursesData.length; i++) {
    courseArchitecture[`course_${i}`] = {
      modules: {},
      info: {
        id: i,
        courseAvailable: coursesData[i].isOpenByDefault,
        courseName: coursesData[i].courseName,
        modulesName: coursesData[i].modulesName,
        lectureCountDB: coursesData[i].lectureCount_DB,
        isOpenByDefault: coursesData[i].isOpenByDefault,
        withFreelance: coursesData[i].withFreelance
      },
    }

    for(let j = 0; j < coursesData[i].lectureCount_DB.length; j++){
      courseArchitecture[`course_${i}`][`modules`][j] = {
        lectures: {},
        info: {
          moduleAvailable: coursesData[i].isOpenByDefault,
        },
      }

      for(let k = 0; k < coursesData[i].lectureCount_DB[j]; k++) {
        if(k === (coursesData[i].lectureCount_DB[j] - 1)){
          courseArchitecture[`course_${i}`][`modules`][j][`lectures`][k] = {
            lectureAvailable: coursesData[i].isOpenByDefault,
            quizProgress: '0000000000',
            pageProgress: '0000000000',
            isAwardReceived: false,
          }
        }else{
          courseArchitecture[`course_${i}`][`modules`][j][`lectures`][k] = {
            lectureAvailable: coursesData[i].isOpenByDefault,
            quizProgress: '0000000000',
            pageProgress: '0000000000',
            isAwardReceived: false,
          }
        }
      }
    }
  }

  return courseArchitecture;
}

export const createUserCourseDoc = async (unlockedCourses, oldCourses, uid, dispatch) => {
  let courseArchitecture = {}

  unlockedCourses.forEach((course) => {
    courseArchitecture[`course_${course.info.id}`] = {
      modules: {},
      info: {
        id: course.info.id,
        courseAvailable: true,
        updatedAt: getCurrentTimeInMoscow(),
      },
    }

    for(let i = 0; i < course?.info?.moduleNames?.length; i++){
      courseArchitecture[`course_${course.info.id}`][`modules`][i] = {
        lectures: {},
        info: {
          moduleAvailable: true,
        },
      }
      for(let j = 0; j < course?.info?.lectureCount[i]; j++) {
        courseArchitecture[`course_${course.info.id}`][`modules`][i][`lectures`][j] = {
          lectureAvailable: true,
          quizProgress: '0000000000',
          pageProgress: '0000000000',
          isAwardReceived: false,
        }
      }
    }
})

  try {
    await setDoc(doc(db, "courses", uid), courseArchitecture, { merge: true });

    const courseSnap = await getDoc(doc(db, "courses", uid))
    if (courseSnap.exists()) {
      dispatch(setCourseData(courseSnap.data()));
    } else {
      console.log("Не найдено courseSnap!");
    }
  } catch (e) {
    console.log('Error: ', e);
  }
}
