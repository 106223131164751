import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useEffect, useState} from "react";
import _ from "lodash";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function DropDownOption({ list, onSelectHandler, isMainBtnDisabled, variant = 'contained' }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mainButtonText, setMainButtonText] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!mainButtonText) {
      setMainButtonText(list[0]?.name);
    }
  }, [list])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    const selectedCourse = list.find(el => el.id === id);
    setMainButtonText(selectedCourse?.name || list[0].name);
    onSelectHandler(selectedCourse?.id || 0);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={variant}
        disabled={list?.length === 0 || isMainBtnDisabled}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {mainButtonText}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          !_.isEmpty(list) && list.map((el, index) => (
            <MenuItem key={`item-${el.name}-${index}`} onClick={() => handleClose(el.id)} disableRipple>{index + 1}. {el.name}</MenuItem>
          ))
        }
      </StyledMenu>
    </div>
  );
}