import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid, LinearProgress, TextField,
  Typography
} from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Article from "../Article";
import {Link, useSearchParams} from "react-router-dom";
import {articleStorage} from "../../externalData/Articles";
import {getDailySalary} from "../Home/Profile";
import {useDispatch, useSelector} from "react-redux";
import {giveUserAwards} from "../../utils/services";
import {getMaxCourseAward, serviceEconomics} from "../../utils/services/ServiceEconomics";
import CustomBadge from "../CustomBadge";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import GamePointsBadge from "../GamePointsBadge";
import {
  getFullLessonCountInCourse,
  getFullModuleCountInCourse, useAllCoursesProgress
} from "../../utils/services/сalculationService/courseProgress";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import Loader from "../Loader";
import {unlockCourseByPromoCode} from "../../utils/services/firestoreHandlers";
import LoaderWithOverlay from "../LoaderWithOverlay";
import {createDBArchitecture, createUserCourseDoc} from "../../utils/services/createCourseDBArchitecture";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../firebase";


const ContentWrapper = styled('div')`
  max-width: 1190px;
  margin: auto;
`;
const PageTitle = styled('h1')`
    text-align: center;
    font-size: 60px;
    margin: 1rem 0;
  `;
const Description = styled('p')`
    text-align: center;
    color: #6c757d;
  `;
const NewsNavPanel = styled('div')`
  display: flex;
  align-items: center;
  & ul {
    display: flex;
  }
  
  @media (max-width: 430px) {
    flex-direction: column;
    & ul { 
      padding-left: 10px;
    }
  }
`;
const NewsNavElement = styled('div')`
  cursor: pointer;
  list-style-type: none;
  margin-right: 18px;
  font-size: 14px;
  
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`;

const TodoListContainer = styled('div')`
  & p,
  & span{
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  & div {
    margin-bottom: 16px;
  }
`;
const InteractiveCard = styled(Card)`
  opacity: ${props => props.disabled ? '0.5' : '1'};
  position: relative;

  &:hover {
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }
  
  @media (max-width: 430px) {
    margin: auto;
  }
`;
const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`;
// Материал для новостей
// const CardMediaMobile = styled(CardMedia)`
//   @media (max-width: 430px) {
//     width: 100%!important;
//   }
// `;

const CardMediaMobile = styled(CardMedia)`
  @media (max-width: 430px) {
    position: absolute!important;
    width: 100px!important;
    height: 41px!important;
    border-radius: 0 0px 0px 15px!important;
    right: 0!important;
    top: 0!important;
    padding: 0!important;
  }
`;
const CardContentMobile = styled(CardContent)`
  display: flex;
  @media (max-width: 430px) {
    flex-direction: column;
  }
`;
const CardDescription = styled('div')`
  margin: 0;
  min-height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content auto 24px;
  width: 100%;
  grid-gap: 4px 0;
  & span {
    font-size: 14px;
  }
`;

const StickyActionPanel = styled(Grid)`
  position: relative;
  padding-top: 96px!important;
  @media (max-width: 430px) {
    display: none;
  }
`;

const AwardStats = styled('div')`
  display: flex;
`;

const DetailsBox = styled('span')`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 430px) {
    & a {
      height: 26px;
    }
    & button {
      font-size: 0.4rem!important;
      font-weight: 600;
      height: 22px;
    }
  }
`;

const PromoCodeContainer = styled('div')`
  display: flex;
  margin: 0 0 20px 0;
`;

const News = () => {
  const dispatch = useDispatch();
  const { innerWidth: width, innerHeight: height } = window;
  const userData = useSelector(state => state.repos.userData);
  const courseData = useSelector(state => state.repos.courseData);
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const userAuthData = useSelector(state => state.repos.userAuthData);
  const [selectedTag, setSelectedTag] = useState(0);
  const [newsData, setNewsData] = useState([...articleStorage]);
  const [urlParametersId, setUrlParametersId] = useSearchParams();
  const [completedLessons, totalCountLessons] = useAllCoursesProgress(courseData);
  const articleLinkTitle = urlParametersId.get('title');
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function LinearProgressWithLabel(props) {
    const completedLessons = props.completedLessons[props.index];
    const totalCountLessons = props.totalCountLessons[props.index];

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="secondary" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${completedLessons || '0'}/${totalCountLessons || '0'}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const CardWrapper = ({ elem, index, rightCard }) => (
    <InteractiveCard
      rightCard={rightCard}
    >
      <CardContentMobile>
        <CardMediaMobile
          component="img"
          sx={{width: 250, height: 140, marginRight: 2, minWidth: '250px'}}
          image={elem.img}
          alt={elem.title}
        />
        <CardDescription>
          <TypographyMobile variant="h6" component="div" fontSize="18px" fontWeight="600" mobileSize={1}>
            {elem.title}
          </TypographyMobile>
          <TypographyMobile variant="body2" color="text.secondary" fontSize="14px" height="42px" mobileSize={0.8}>
            {elem.description.length > (width < 500 ? 70 : 120) ? `${elem.description.substr(0, width < 500 ? 70 : 124)}...` : elem.description}
          </TypographyMobile>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1rem!important', opacity: '0.6' }}>
            <TypographyMobile variant="h6" style={{ display: 'flex', alignItems: 'center' }} mobileSize={0.8}>
              <CalendarTodayIcon sx={{ marginRight: '8px', width: '16px' }} />
              <TypographyMobile mobileSize={0.6}>{elem.date}</TypographyMobile>
            </TypographyMobile>
            <TypographyMobile mobileSize={0.6}>{elem.tagName}</TypographyMobile>
          </div>
        </CardDescription>
      </CardContentMobile>
    </InteractiveCard>
  )

  const selectTagHandler = (e) => {
    if (e.target.title === 'all') setNewsData([...articleStorage])
    if (e.target.title === 'useful') setNewsData([...articleStorage.filter(el => el.tag === 'useful')])
    if (e.target.title === 'platform') setNewsData([...articleStorage.filter(el => el.tag === 'platform')])
  }

  const getSelectedForReadingArticle = link => articleStorage.find(el => el.link === link);

  const getCourseByPromoCode = () => {
    setIsLoading(true);
    unlockCourseByPromoCode(promoCode).then(unlockedCourses => {
      if (unlockedCourses.length) {
        createUserCourseDoc(unlockedCourses, courseData, userAuthData.uid, dispatch).then(r => setIsLoading(false));
      } else {
        setPromoCodeError("Курса с данным промокодом не существует");
        setIsLoading(false);
      }
    });
  }

  // const userData = useSelector(state => state.repos.userData);
  // const [isUserAuthorized, setIsUserAuthorized] = useState(JSON.parse(localStorage.getItem('st_user_authorized')))
  // const dispatch = useDispatch();
  //
  // useEffect(async () => {
  //   if (!userData?.platformTraining?.isOpenNews) {
  //     await updatePlatformTrainingHandler(isUserAuthorized, userData, 5, dispatch);
  //   }
  // }, []);

  return(
    <>
      <PageTitle>StuDopolis</PageTitle>
      <Description>Развивайся вместе со StuDopolis</Description>
      <ContentWrapper>
        <Grid style={{maxWidth: 1200, margin: "auto"}} container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={width < 500 ? 12 : 9}>
            <NewsNavPanel>
              {/*Материал для новостей*/}
              {/*<h3>Обновления</h3>*/}
              {/*<ul>*/}
              {/*  <Link to='/'>*/}
              {/*    <NewsNavElement onClick={selectTagHandler} title="all" mobileSize={0.8}>#Все</NewsNavElement>*/}
              {/*  </Link>*/}
              {/*  <Link to='/'>*/}
              {/*    <NewsNavElement onClick={selectTagHandler} title="useful" mobileSize={0.8}>#Полезное</NewsNavElement>*/}
              {/*  </Link>*/}
              {/*  <Link to='/'>*/}
              {/*    <NewsNavElement onClick={selectTagHandler} title="platform" mobileSize={0.8}>#Платформа</NewsNavElement>*/}
              {/*  </Link>*/}
              {/*</ul>*/}
              <h3>Обучение</h3>
              <ul>
                  {/*<NewsNavElement onClick={selectTagHandler} title="all" mobileSize={0.8}>#Все_курсы</NewsNavElement>*/}
                  <NewsNavElement onClick={selectTagHandler} title="all" mobileSize={0.8}>#Мои_курсы</NewsNavElement>
              </ul>
            </NewsNavPanel>
            {/*Материал для новостей*/}
            {/*{*/}
            {/*  !articleLinkTitle ? (*/}
            {/*    newsData.map((article, index) => (*/}
            {/*      <Grid item key={index} sx={{ marginBottom: '10px' }}>*/}
            {/*        <Link to={`?title=${article.link}`}>*/}
            {/*          <CardWrapper elem={article} index={index} rightCard={index % 2 !== 0} />*/}
            {/*        </Link>*/}
            {/*      </Grid >*/}
            {/*    ))*/}
            {/*  ) : <Article article={getSelectedForReadingArticle(articleLinkTitle)}/>*/}
            {/*}*/}
            <PromoCodeContainer>
              <TextField
                id="outlined-required"
                sx={{ marginRight: '10px' }}
                label="Промокод"
                variant="outlined"
                error={promoCodeError}
                value={promoCode}
                helperText={promoCodeError}
                onChange={e => {
                  setPromoCode(e.target.value);
                  setPromoCodeError('');
                }}
                size="small"
                fullWidth
              />
              <Button
                variant="contained"
                color='success'
                onClick={() => getCourseByPromoCode()}
                size="small"
              >
                Получить
              </Button>
            </PromoCodeContainer>
            {
              coursesInfo?.length ? (
                <>
                  {
                    coursesInfo.map((elem, index) => (
                      courseData[`course_${elem.id}`]?.info?.courseAvailable ?
                        <Grid item xs={12} sx={{ marginBottom: '10px' }} key={index}>
                          <Link to={`/course/?courseId=${index}`} key={index}>
                            <InteractiveCard
                              sx={{ maxWidth: '100%' }}
                            >
                              <div style={{ display: 'flex' }}>
                                <CardMediaMobile
                                  component="img"
                                  sx={{ width: '268px', padding: '16px', borderRadius: '20px', height: '194px' }}
                                  image={elem.iconURL}
                                />
                                <CardContent sx={{ flex: '1' }}>
                                  <TypographyMobile gutterBottom variant="h6" component="div" fontSize="22px" mobileSize={1}>
                                    {elem.name}
                                  </TypographyMobile>
                                  <TypographyMobile mobileSize={0.8} sx={{ marginTop: '6px'}} variant="body2" color="text.secondary" fontSize="14px" height="52px">
                                    {elem.description}
                                  </TypographyMobile>
                                  <AwardStats>
                                    <CustomBadge sx={{ marginRight: '8px' }} message="Награда:" colorType={0} small />
                                    <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде опыта">
                                      <DetailsBox style={{ marginRight: '6px' }}>
                                        <GamePointsBadge
                                          count={`+${getMaxCourseAward(getFullLessonCountInCourse(elem))[0]}`}
                                          pointType="1" small rectangular/>
                                      </DetailsBox>
                                    </Tooltip>

                                    <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде GreenCoin">
                                      <DetailsBox>
                                        <GamePointsBadge
                                          count={`+${getMaxCourseAward(getFullLessonCountInCourse(elem))[1]}`}
                                          pointType="0" small rectangular/>
                                      </DetailsBox>
                                    </Tooltip>
                                  </AwardStats>

                                  <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                                    <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Количество лекций">
                                      <BeenhereIcon sx={{ marginRight: '4px', width: '20px' }} />
                                    </Tooltip>
                                    <Box sx={{width: '100%'}}>
                                      <LinearProgressWithLabel
                                        value={Math.round(completedLessons[index]/totalCountLessons[index]*100)}
                                        totalCountLessons={totalCountLessons}
                                        completedLessons={completedLessons}
                                        index={index}
                                      />
                                    </Box>
                                  </div>
                                </CardContent>
                              </div>
                            </InteractiveCard>
                          </Link>
                        </Grid>
                        : null
                    ))
                  }
                  {
                    !Object.values(courseData).find(el => el?.info?.courseAvailable) ?
                      <div style={{ maxWidth: '625px', margin: 'auto' }}>
                        <Alert variant="outlined" severity="info" sx={{ marginTop: '20px', display: 'flex' }}>Введи промокод, чтобы получить доступ к курсам</Alert>
                      </div>
                      : null
                  }
                </>
                ) : <Loader />
            }
          </Grid>

          <StickyActionPanel item xs={3}>
            <TodoListContainer style={{ position: 'sticky', top: '20px' }}>
              <InteractiveCard>
                <CardContent >
                  <h3 style={{ margin: '16px 0' }}>План на день</h3>
                  <div>
                    <p> 1. Забрать ежедневную награду </p>
                    {
                      giveUserAwards(
                        !(userData && userData.careerAccumulatedAmount === 0) || !userData?.platformTraining?.isFirstLectureDone,
                        () => userData && getDailySalary(userData, userAuthData, dispatch),
                        serviceEconomics().careerAward[userData?.careerPosition]?.greenCoin,
                        serviceEconomics().careerAward[userData?.careerPosition]?.getExp,
                        { variant: "outlined", fullWidth: true },
                      )
                    }
                  </div>
                  <div>
                    <p>2. Продолжить обучение</p>
                    {/*<p>2. Выполнить фриланс задачи</p>*/}
                    {/*<Link to="freelance">*/}
                    {/*  <Button*/}
                    {/*    variant="outlined"*/}
                    {/*    fullWidth*/}
                    {/*    color='success'*/}
                    {/*  >Фрилансить</Button>*/}
                    {/*</Link>*/}
                  </div>
                  {/*<div>*/}
                  {/*  <p>3. Продолжить обучение</p>*/}
                  {/*  <Link to="profile">*/}
                  {/*    <Button*/}
                  {/*      variant="outlined"*/}
                  {/*      fullWidth*/}
                  {/*      color='success'*/}
                  {/*    >Перейти к курсам</Button>*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                </CardContent>
              </InteractiveCard>
            </TodoListContainer>
          </StickyActionPanel>
        </Grid>
        { isLoading && <LoaderWithOverlay /> }
      </ContentWrapper>
    </>
  )
};

export default News;


