import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {addResponseMessage, toggleMsgLoader, Widget} from "react-chat-widget";
import 'react-chat-widget/lib/styles.css';
import './style.css';
import styled from "styled-components";

const WidgetWrapper = styled(Widget)`
  & img .rcw-open-launcher {
    padding: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, 50%);
  }
  & .rcw-conversation-container > .rcw-header {
    background-color: red;
  }
`;

function ChatGPTAssistant() {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  // Метод для отправки запроса к API
  const sendRequest = async () => {
    setLoading(true);
    toggleMsgLoader(true);
    try {
      const response = await axios.post('https://api.openai.com/v1/engines/text-davinci-003/completions', {
        prompt: input,
        max_tokens: 3000,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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',
        },
      });

      setResponse(response.data.choices[0].text);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    toggleMsgLoader(false);
  };

  useEffect(() => {
    addResponseMessage(response || 'Привет! Я твой ИИ-помощник, задавай любые вопросы:)');
  }, [response]);

  return (
    <div>
      <WidgetWrapper
        handleNewUserMessage={sendRequest}
        title="StuDopolis AI"
        subtitle={false}
        resizable
        autofocus
        toggleMsgLoader={loading}
        senderPlaceHolder="Введите текст"
        handleTextInputChange={(e) => setInput(e.target.textContent)}
      />
    </div>
  );
}

export default ChatGPTAssistant;