import React from "react";
import styled from "styled-components";
import {textColor1} from "../../constants/colors";
import parse from 'html-react-parser';

const ContentSubTitle = styled('h3')`
  margin-top: 30px;
  padding-left: 62px;
`

const ContentText = styled('p')`
  color: ${textColor1};
  margin-top: 10px;
  text-align: justify;
  font-size: 18px;
  padding: 0 62px;

  & pre {
    padding: 0 12px;
    white-space: break-spaces;
    word-break: break-word;
  }

  white-space: break-spaces;
  word-break: break-word;
  
  @media (max-width: 430px) {
    padding: 0 12px;
    font-size: 14px;
    & pre {
      font-size: 14px;
      padding: 0 12px!important;
    }
  }
`

const FrameVideo = styled('iframe')`
  width: 65vw;
  height: 65vh;
  margin: 0 58px;
  & .ytp-title-text {
    display: none!important;
  }
  a[href*="youtube.com"] {
    display: none;
  }
  & .ytp-chrome-top.ytp-show-cards-title {
    display: none!important;
  }
  @media (max-width: 430px) {
    height: 200px;
    width: 100%;
    margin: 20px 0px 0px 0px!important;
  }
`

const PageContent = ({ pageData }) => (
  pageData.map((el, index) => {
    if(Object.keys(el)[0] === 'pageSecondTitles'){
      return (
        <ContentSubTitle key={index}>
          {el.pageSecondTitles}
        </ContentSubTitle>
      )
    }else if(Object.keys(el)[0] === 'pageText'){
      return(
        <ContentText key={index}>
          {parse(el.pageText)}
        </ContentText>
      )
    }else if(Object.keys(el)[0] === 'pageVideo'){
      return(
        <FrameVideo
          src={el.pageVideo}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )
    }
  })
);

export default PageContent