import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from "styled-components";

const Overlay = styled(Box)`
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #2727279e;
  top: 0;
  left: 0;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  
  & span {
    width: 80px!important;
    height: 80px!important;
  }
`;

const LoaderWithOverlay = () => {
  return (
    <Overlay>
      <CircularProgress />
    </Overlay>
  );
}

export default LoaderWithOverlay;