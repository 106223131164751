import React, {useEffect, useState} from "react";
import styled from "styled-components";

import MainPageTitle from "../../containers/MainPageTitle";
import { mainColor } from "../../constants/colors";
import {
  Alert, Box,
  Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem, OutlinedInput,
  Select,
  Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getDownloadMaterials, materialCollection} from "../../data/courseData/index";
import FlatCard from "../../FlatCard";
import Loader from "../Loader";
import _ from 'lodash';

const ContentWrapper = styled('div')`
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const FreelanceNavPanel = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 24px;
  @media (max-width: 430px) {
    padding: 0;
  }
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
    margin: 0!important;
    margin-top: 20px;
  }
`

const SwitchCourseButton = styled.span`
  font-size: 0.8rem;
  font-weight: 300;
  color: blueviolet;
  
  &:hover{
   cursor: pointer; 
  }
`;

const Freelance = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const freelanceData = useSelector(state => state.repos.freelanceData);
  const courseData = useSelector(state => state.repos.courseData);
  const courseMaterials = useSelector(state => state.repos.courseMaterials);
  const [currentCourseId, setCurrentCourseId] = useState(0);
  const [filteredTasks, setFilteredTasks] = useState(null);
  const [open, setOpen] = useState(false);
  const [switchCourseValue, setSwitchCourseValue] = useState(0);

  const insertModuleTitle = (taskIndex, moduleIndex) => {
    if (courseData) {
      const courseInfo = courseData[`course_${currentCourseId}`].info;
      const getCountOfAddedTask = () => courseInfo.lectureCountDB.slice(0, moduleIndex).reduce((sum, current) => sum + current - 1, 0)

      if (taskIndex - getCountOfAddedTask() === 0) {
        return (
          <Grid item xs={12} key={`${taskIndex}-${courseInfo.modulesName[moduleIndex]}`} marginTop={width < 500 ? 5 : 0}>
            <TypographyMobile gutterBottom component="div" fontSize='0.8rem' fontWeight="600" marginTop="20px" mobileSize={0.8}>
              Модуль {+moduleIndex + 1}. {courseInfo.modulesName[moduleIndex]}
            </TypographyMobile>
          </Grid>
        )
      }
      return null;
    }
  };

  const getAvailableTasks = (courseId) => {
    const availableTasks = [];
    if(freelanceData[`course_${courseId}`]){
      const modulesData = freelanceData[`course_${courseId}`].modules
      const getTasksLengthWithControlWork = (moduleId) => freelanceData[`course_${courseId}`].info.withControlWorkInModules
        ? Object.keys(modulesData[moduleId].lectures).length - 1
        : Object.keys(modulesData[moduleId].lectures).length;

      for(let moduleId in modulesData) {
        for(let lectureId in modulesData[moduleId].lectures){
          if (lectureId < getTasksLengthWithControlWork(moduleId)) {
            const data = modulesData[moduleId].lectures[lectureId];
            availableTasks.push({courseId: courseId, moduleId: moduleId, lectureId: lectureId, taskId: 1, data: data[`task_1`]})
          }
        }
      }
    }

    return availableTasks
  }

  const popupHandleChange = (event) => {
    setSwitchCourseValue(Number(event.target.value) || '');
  };

  const popupHandleClickOpen = () => {
    setOpen(true);
  };

  const popupHandleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const changeCourseIdHandler = (event, reason) => {
    setCurrentCourseId(switchCourseValue - 1);
    popupHandleClose(event, reason);
  }

  useEffect(() => {
    setFilteredTasks(getAvailableTasks(currentCourseId));
  }, [freelanceData, currentCourseId])

  return(
    <>
      <ContentWrapper>
        <MainPageTitle>Мой фриланс</MainPageTitle>
        <Grid style={{maxWidth: 1190, margin: "auto"}} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {
            !_.isEmpty(freelanceData) && filteredTasks ? (
              <>
                <Grid style={{maxWidth: 1190, margin: "auto"}} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <FreelanceNavPanel>
                    <TypographyMobile gutterBottom component="div" fontSize='1.2rem' fontWeight="600" mobileSize={0.6}>
                      Курс: {freelanceData[`course_${currentCourseId}`]?.info?.courseName}
                      <div>
                        <SwitchCourseButton onClick={popupHandleClickOpen}>сменить курс</SwitchCourseButton>
                        <Dialog sx={{ width: '100%' }} disableEscapeKeyDown open={open} onClose={popupHandleClose}>
                          <DialogTitle>Выбери курс</DialogTitle>
                          <DialogContent>
                            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap'}}>
                              <FormControl sx={{ m: 1, minWidth: '20vw' }}>
                                <InputLabel id="demo-dialog-select-label">Курс</InputLabel>
                                <Select
                                  labelId="demo-dialog-select-label"
                                  id="demo-dialog-select"
                                  value={switchCourseValue}
                                  onChange={popupHandleChange}
                                  input={<OutlinedInput label="Курс" />}
                                >
                                  {
                                    Object.values(courseData).map((el, index) => el?.info?.courseAvailable ? (
                                      <MenuItem value={el.info.id + 1}>{el?.info?.courseName}</MenuItem>
                                    ): null)
                                  }
                                </Select>
                              </FormControl>
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={popupHandleClose}>Отмена</Button>
                            <Button onClick={changeCourseIdHandler}>Ok</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </TypographyMobile>
                    <TypographyMobile sx={{marginTop: '6px'}} variant="body2" color="text.secondary" fontSize="14px" mobileSize={0.6}>
                      Выполнено задач {filteredTasks && filteredTasks.filter(el => el.data.isAwardReceived).length} из {filteredTasks?.length}
                    </TypographyMobile>
                  </FreelanceNavPanel>
                </Grid>
                {
                  courseData[`course_${currentCourseId}`].info.withFreelance ? (
                    filteredTasks.map((elem, index) => {
                      const externalData = getDownloadMaterials(courseMaterials)[elem.courseId][elem.moduleId][elem.lectureId].freelanceTasks[elem.taskId]
                      return (
                        <>
                          {
                            insertModuleTitle(index, elem.moduleId)
                          }
                          <Grid item xs={ width > 500 ? 6 : 12 } key={index}>
                            {
                              elem.data.taskAvailable ? (
                                <Link to={`task?courseId=${elem.courseId}&moduleId=${elem.moduleId}&lectureId=${elem.lectureId}&taskId=${elem.taskId}`} key={index}>
                                  <FlatCard elem={elem} externalData={externalData} isAvailable isCompleted={elem.data.isAwardReceived} />
                                </Link>
                              ) : (
                                <FlatCard elem={elem} externalData={externalData} />
                              )
                            }
                          </Grid>
                        </>
                      )
                    })
                  ) : (
                    <div style={{ maxWidth: '670px', margin: 'auto' }}>
                      <Alert variant="outlined" severity="info" sx={{ marginTop: '20px', display: 'flex' }}>Доступных фриланс-задач в данном курсе нет</Alert>
                    </div>
                  )
                }
              </>
              ) : <Loader />
          }
          {
            !_.isEmpty(freelanceData) && !filteredTasks?.length ?
              <div style={{ maxWidth: '670px', margin: 'auto' }}>
                <Alert variant="outlined" severity="info" sx={{ marginTop: '20px', display: 'flex' }}>Доступных фриланс-задач нет. Необходимо выбрать курс</Alert>
              </div>
            : null
          }
        </Grid>
      </ContentWrapper>
    </>
  )
}

export default Freelance