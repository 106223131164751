import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import CustomBadge from "../../CustomBadge";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import GamePointsBadge from "../../GamePointsBadge";
import {getMaxCourseAward} from "../../../utils/services/ServiceEconomics";
import {
  getFullLessonCountInCourse,
  getFullModuleCountInCourse
} from "../../../utils/services/сalculationService/courseProgress";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {getCurrentTimeInMoscow, getLocalTimeFromUnixTime} from "../../../utils/services";
import AlertDialogSlide from "../../AlertDialogSlide";
import Loader from "../../Loader";
import React from "react";
import {getCoursesInfoFromDB, updateCourseCollection} from "../../../utils/services/firestoreHandlers";
import Logo from "../../../media/platformUpdateImg.png";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";

const AddBtnStyle = {
  width: '100%',
  height: '80px',
  border: '2px dashed',
  fontSize: '26px',
  fontWeight: 600
}

const InteractiveCard = styled(Card)`
  opacity: ${props => props.disabled ? '0.5' : '1'};
  position: relative;
  &:hover{
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }
`;

const CardMediaMobile = styled(CardMedia)`
  @media (max-width: 430px) {
    position: absolute!important;
    width: 100px!important;
    height: 41px!important;
    border-radius: 0 0px 0px 15px!important;
    right: 0!important;
    top: 0!important;
    padding: 0!important;
  }
`;

const AwardStats = styled('div')`
  display: flex;
`;

const DetailsBox = styled('span')`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 430px) {
    & a {
      height: 26px;
    }
    & button {
      font-size: 0.4rem!important;
      font-weight: 600;
      height: 22px;
    }
  }
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

export const CoursePage = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const courseData = useSelector(state => state.repos.courseData);
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const userData = useSelector(state => state.repos.userData);

  const createCourseHandler = () => {
    if (coursesInfo?.length && userData) {
      setIsLoading(true);
      const getNewCourseId = () => coursesInfo.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
      updateCourseCollection(`course_${getNewCourseId() + 1}`, async () => {
        await getCoursesInfoFromDB(userData, dispatch);
        setIsLoading(false);
      }, {
        info: {
          id: getNewCourseId() + 1,
          name: 'Новый курс',
          description: 'Описание курса',
          owner: userData.email,
          promoCode: 'newCourse2023',
          createdAt: getCurrentTimeInMoscow(),
          updatedAt: getCurrentTimeInMoscow(),
          iconURL: Logo,
          studentsCount: 0,
          studentsCourseCompletedCount: 0,
          passingTime: '10 дней',
          status: 'доступно',
          disabled: false,
          moduleNames: [],
          moduleIds: [],
          moduleIcons: [],
          lectureCount: [],
          withControlWorkInModules: false,
          isOpenByDefault: false,
          withFreelance: false,
        }
      })
    }
  }
  const getFilteredCoursesInfo = () => (
    coursesInfo
      .filter(el => el.owner === userData.email)
      .sort((a, b) => b.updatedAt - a.updatedAt)
  );

  return (
    <Grid style={{ maxWidth: '1190px', margin: 'auto' }} container spacing={2} marginBottom={10}>
      {
        coursesInfo?.length && userData ? (
          <>
            {
              getFilteredCoursesInfo().map((elem, index) => (
                <Grid item xs={12} key={index}>
                  <Link to={`/teacher/course?courseId=${elem.id}`} key={index}>
                    <InteractiveCard
                      sx={{ maxWidth: '100%' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <CardMediaMobile
                          component="img"
                          sx={{ width: '268px', padding: '16px', borderRadius: '20px', height: '194px' }}
                          image={elem.iconURL}
                        />
                        <CardContent sx={{ flex: '1' }}>
                          <TypographyMobile gutterBottom variant="h6" component="div" fontSize="22px" mobileSize={1}>
                            {elem.name}
                          </TypographyMobile>
                          <TypographyMobile mobileSize={0.8} sx={{ marginTop: '6px'}} variant="body2" color="text.secondary" fontSize="14px" height="52px">
                            {elem.description}
                          </TypographyMobile>
                          <AwardStats>
                            <CustomBadge sx={{ marginRight: '8px' }} message="Награда:" colorType={0} small />
                            <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде опыта">
                              <DetailsBox style={{ marginRight: '6px' }}>
                                <GamePointsBadge
                                  count={`+${getMaxCourseAward(getFullLessonCountInCourse(courseData[`course_${index}`]), getFullModuleCountInCourse(courseData[`course_${index}`]))[0]}`}
                                  pointType="1" small rectangular/>
                              </DetailsBox>
                            </Tooltip>

                            <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Награда в виде GreenCoin">
                              <DetailsBox>
                                <GamePointsBadge
                                  count={`+${getMaxCourseAward(getFullLessonCountInCourse(courseData[`course_${index}`]), getFullModuleCountInCourse(courseData[`course_${index}`]))[1]}`}
                                  pointType="0" small rectangular/>
                              </DetailsBox>
                            </Tooltip>
                          </AwardStats>

                          <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'end' }}>
                            <Tooltip style={{ marginRight: '30px' }} TransitionComponent={Zoom} placement="top" arrow title="Продолжительность курса">
                              <DetailsBox>
                                <TimelapseIcon sx={{ marginRight: '4px', width: '20px' }} /> {elem?.passingTime}
                              </DetailsBox>
                            </Tooltip>
                            <Tooltip TransitionComponent={Zoom} placement="top" arrow title="Дата последнего изменения">
                              <DetailsBox>
                                <EventNoteIcon sx={{ marginRight: '4px', width: '20px' }} /> Изменен: {getLocalTimeFromUnixTime(elem.updatedAt)}
                              </DetailsBox>
                            </Tooltip>
                          </div>
                        </CardContent>
                      </div>
                    </InteractiveCard>
                  </Link>
                </Grid>
              ))
            }
            <Grid item xs={12} key="add-new-course">
              <AlertDialogSlide
                mainBtnText="+"
                mainBtnStyle={AddBtnStyle}
                dialogTitle="Уверены, что хотите создать новый курс?"
                dialogDescription = "Создание курса может занять некторое время"
                onAgreeHandler={() => createCourseHandler()}
              />
            </Grid>
          </>
        ) : <Loader />
      }
    </Grid>
  )
};