import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { mainColor } from "../../../constants/colors";
import { pageNavigationData } from "../../../externalData";
import { useSelector } from "react-redux";
import { itHasPermissions } from "../../../utils/services";
import NavElement from "../NavElement";
import admin from "./media/admin_logo_important.png";
import teacherData from "./teacherData.json";
import shop from "./media/bitcoin.png";
import {ROLES_LIST} from "../../../constants/constants";

const NavContainer = styled('nav')`
  background: ${mainColor};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 90px;
  border-radius: 0 8px 8px 0;
  z-index: 250;

  @media (min-width: 430px) {
    top: 0;
  }
  @media (max-width: 430px) {
    width: 100%;
    height: 58px;
  }
`
const ListWrapper = styled('ul')`
  list-style: none;
  color: white;

  @media (max-width: 430px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`

const Navbar = () => {
  const userAuthData = useSelector(state => state.repos.userAuthData);
  const userData = useSelector(state => state.repos.userData);

  const isSectionOnTraining = (el) => {
    if (el.link === 'rating' && !userData?.platformTraining?.isFirstFreelanceTaskDone) return el.link;
    if (el.link === 'freelance' && !userData?.platformTraining?.isFirstGPayBought) return el.link;
    if (el.link === 'shop' && !userData?.platformTraining?.isFirstSalaryReceived) return el.link;
    if (el.link === 'news' && !userData?.platformTraining?.isOpenRating) return el.link;
    return false;
  }

  return(
    <NavContainer>
      <ListWrapper>
        {
          pageNavigationData.map((list, index) =>
            itHasPermissions(userData.role || ROLES_LIST.STUDENT, list.role) ? (
              <Link to={ (userAuthData?.uid || list.demoPage) && !isSectionOnTraining(list) ? `/${list.link}` : '/'} key={index}>
                <NavElement
                  key={index}
                  titleName={list?.name}
                  link={list.link}
                  titleImg={list.img}
                  titleMsg={list.msg}
                  isLogo={list?.logo}
                  disabled={!(userAuthData?.uid || list.demoPage) || isSectionOnTraining(list)}
                />
              </Link>
            ) : null
          )
        }
      </ListWrapper>
    </NavContainer>
  )
}

export default Navbar