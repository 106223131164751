import Article_1 from "./1_Kak_sozdany_tvoi_lyubimye_videoigry_I_chto_takoe_Gamedev.json";
import Article_2 from "./2_Kto_takoj_etot_igrovoj_dvizhok.json";
import Article_3 from "./3_Kak_stat_razrabotchikom_videoigr_I_kak_sdelat_na_etom_kareru.json";
import Article_4 from "./4_Kak_stat_CSharp_programmistom.json";
import Article_5 from "./5_Kak_postroit_kareru_v_GAMEDEV_Intervyu.json";
import Update_5 from "./6_PLAT_UPDATE_Added_studopolis_Assistent.json";

export const articleStorage = [
  // Update_5,
  Article_4,
  Article_1,
  Article_5,
  Article_2,
  Article_3,
]