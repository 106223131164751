import React from 'react';
import styled from "styled-components";

import {hoverColor, secondColor, textColor2} from "../../constants/colors";

const SectionButton = styled('button')`
  border: none;
  background: ${props => props.active ? hoverColor() : "none"};
  color: white;
  width: 100%;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid ${props => props.sectionDone ? secondColor() : textColor2()};
  padding: 10px 12px;
  opacity: ${props => props.disabled ? 0.3 : 1};
  &:hover{
    cursor: pointer;
    background-color: ${hoverColor()};
  }

  @media (max-width: 430px) {
    font-size: 0.8rem;
  }
`;

export const SideNavigation = ({ sectionTitles, onClickHandler, disableHandler, isActiveSection, isSectionDone }) => (
  <div>
    {
      sectionTitles && sectionTitles.map((section, sectionIndex) => (
        <SectionButton
          key={`section-${sectionIndex}`}
          sectionDone={isSectionDone(sectionIndex)}
          active={isActiveSection(sectionIndex)}
          onClick={() => onClickHandler(sectionIndex)}
          disabled={disableHandler(sectionIndex)}
        >
          {section}
        </SectionButton>
      ))
    }
  </div>
);
