import React, {useContext, useEffect, useState} from "react";
import { auth, db } from '../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, getDoc} from "firebase/firestore";
import {createDBArchitecture} from "../utils/services/createCourseDBArchitecture";
import {useDispatch, useSelector} from "react-redux";
import {setCourseData, setUserData, setUsersAuthData} from "../utils/reducers/repoReducer";
import {createFreelanceDBArchitecture} from "../utils/services/createFreelanceDBArchitecture";
import item_5 from "../media/platformer2D_screen.png";
import item_3 from "../media/apk.png";
import flat_default from "../media/apartments/flat_default.jpg";
import flat_economy_1 from "../media/apartments/flat_economy_1.jpg";
import flat_economy_2 from "../media/apartments/flat_economy_2.jpg";
import flat_standard_1 from "../media/apartments/flat_standard_1.jpg";
import flat_standard_2 from "../media/apartments/flat_standard_2.jpg";
import flat_premium_1 from "../media/apartments/flat_premium_1.jpg";
import flat_lux_1 from "../media/apartments/flat_lux_1.jpg";
import flat_lux_2 from "../media/apartments/flat_lux_2.jpg";
import flat_lux_3 from "../media/apartments/flat_lux_3.jpg";
import item_2 from "../media/asset.png";
import unity_icon from "../media/courseWallpaper/unity.png";
import unity3DaysIntensive from "../media/courseWallpaper/unity_3Days_intensive.png";
import frontend_icon from "../media/courseWallpaper/frontend.png";
import minecraft_icon from "../media/courseWallpaper/minecraft.png";
import python_icon from "../media/courseWallpaper/python.png";
import figma_icon from "../media/courseWallpaper/figma.png";
import adobe_illustrator_icon from "../media/courseWallpaper/adobe_illustrator.png";
import blender_icon from "../media/courseWallpaper/blender.png";
import unreal_engine_icon from "../media/courseWallpaper/unreal_engine.png";
import ksp_icon from "../media/courseWallpaper/kerbal_space_programm.png";
import target_icon from "../media/courseWallpaper/target.png";
import {createShopDBArchitecture} from "../utils/services/createShopDBArchitecture";
import {ROLES_LIST} from "../constants/constants";

const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)

let user = '';

export const AuthProvider = ({children}) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  const signup = (userEnteredData, setError, handleClick) => (
    createUserWithEmailAndPassword(auth, userEnteredData.emailRef.current.value, userEnteredData.passwordRef.current.value)
      .then(async (userCredential) => {
        user = userCredential.user;
        setDoc(doc(db, "users", user.uid), {
          firstName: userEnteredData.firstNameRef.current.value,
          photoIdRef: userEnteredData.photoSelected,
          lastName: userEnteredData.lastNameRef.current.value,
          email: user.email,
          createdAt: user.metadata.createdAt,
          lastLoginAt: user.metadata.lastLoginAt,
          experienceAmount: 0,
          goldCoinAmount: 10,
          greenCoinAmount: 0,
          resolvedFreelanceTaskCount: 0,
          careerPosition: 0,
          careerAwardDate: 0,
          role: ROLES_LIST.STUDENT,
          purchasedApartment: 'Стартовая Студия: "Уютное гнездышко"',
          careerAccumulatedAmount: 0,
          platformTraining: {
            isFirstLectureDone: true,
            isFirstSalaryReceived: true,
            isFirstGPayBought: true,
            isFirstFreelanceTaskDone: true,
            isOpenRating: true,
            isOpenNews: true,
          }
        }) //TODO: обучение отключено - обнови обучение полностью

        // setDoc(doc(db, "courses", user.uid), createDBArchitecture())
        setDoc(doc(db, "freelance", user.uid), createFreelanceDBArchitecture())
        setDoc(doc(db, "shop", user.uid), createShopDBArchitecture());

        handleClick()
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(`Ошибка: пользователь с данным email существует`)
      })
  )

   const signin = (userEnteredData, setError, setLoading) => {
    signInWithEmailAndPassword(auth, userEnteredData.emailRef.current.value, userEnteredData.passwordRef.current.value)
      .then(async (userCredential) => {
        user = userCredential.user;
        localStorage.setItem('st_user_authorized', JSON.stringify(user));
        dispatch(setUsersAuthData(JSON.parse(localStorage.getItem('st_user_authorized'))));

        const userDataSnap = await getDoc(doc(db, "users", user.uid));
        const courseSnap = await getDoc(doc(db, "courses", user.uid));
        console.log('authorized')

        if (userDataSnap.exists()) {
          dispatch(setUserData(userDataSnap.data()));
        } else {
          console.log("No such userDataSnap!");
        }

        if (courseSnap.exists()) {
          dispatch(setCourseData(courseSnap.data()));
        } else {
          console.log("No such courseSnap!");
        }
      })
      .catch((error) => {
        console.log(error)

        const errorCode = error.code;
        const errorMessage = error.message;
        setLoading(false)
        setError(`Ошибка: проверьте правильность введенных данных`)
      })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setLoading(false)
      setCurrentUser(user)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    signup,
    signin,
  }

  return(
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
