import FrontEnd_1_1 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s1.json"
import FrontEnd_1_2 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s2.json"
import FrontEnd_1_3 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s3.json"
import FrontEnd_1_4 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s4.json"
import FrontEnd_1_5 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s5.json"
import FrontEnd_1_6 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s6.json"
import FrontEnd_1_7 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s7.json"
import FrontEnd_1_8 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s8.json"
import FrontEnd_1_9 from "../../../externalData/CSharp/FrontEnd_1/FrontEnd_s9.json"

import FrontEnd_2_1 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s1.json"
import FrontEnd_2_2 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s2.json"
import FrontEnd_2_3 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s3.json"
import FrontEnd_2_4 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s4.json"
import FrontEnd_2_5 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s5.json"
import FrontEnd_2_6 from "../../../externalData/CSharp/FrontEnd_2/FrontEnd_s6.json"

import FrontEnd_3_1 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s1.json"
import FrontEnd_3_2 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s2.json"
import FrontEnd_3_3 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s3.json"
import FrontEnd_3_4 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s4.json"
import FrontEnd_3_5 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s5.json"
import FrontEnd_3_6 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s6.json"
import FrontEnd_3_7 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s7.json"
import FrontEnd_3_8 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s8.json"
import FrontEnd_3_9 from "../../../externalData/CSharp/FrontEnd_3/FrontEnd_s9.json"


export const cSharpMaterials = () => (
  [
    [FrontEnd_1_1, FrontEnd_1_2, FrontEnd_1_3, FrontEnd_1_4, FrontEnd_1_5,
      FrontEnd_1_6, FrontEnd_1_7, FrontEnd_1_8, FrontEnd_1_9,],
    [FrontEnd_2_1, FrontEnd_2_2, FrontEnd_2_3, FrontEnd_2_4, FrontEnd_2_5, FrontEnd_2_6],
    [FrontEnd_3_1, FrontEnd_3_2, FrontEnd_3_3, FrontEnd_3_4, FrontEnd_3_5,
      FrontEnd_3_6, FrontEnd_3_7, FrontEnd_3_8, FrontEnd_3_9],
  ]
)





