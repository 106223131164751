import Less_1_1 from "../../../externalData/Unity3DaysIntensive/Less_1/Less_s1.json"
import Less_1_2 from "../../../externalData/Unity3DaysIntensive/Less_1/Less_s2.json"
import Less_1_3 from "../../../externalData/Unity3DaysIntensive/Less_1/Less_s3.json"

export const unity3DaysIntensiveMaterials = () => (
  [
    [Less_1_1, Less_1_2, Less_1_3],
  ]
)





