import FrontEnd_1_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s1.json"
import FrontEnd_1_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s2.json"
import FrontEnd_1_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s3.json"
import FrontEnd_1_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s4.json"
import FrontEnd_1_5 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s5.json"
import FrontEnd_1_6 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s6.json"
import FrontEnd_1_7 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s7.json"
import FrontEnd_1_8 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_1/FrontEnd_s8.json"

import FrontEnd_2_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s1.json"
import FrontEnd_2_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s2.json"
import FrontEnd_2_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s3.json"
import FrontEnd_2_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s4.json"
import FrontEnd_2_5 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s5.json"
import FrontEnd_2_6 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s6.json"
import FrontEnd_2_7 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s7.json"
import FrontEnd_2_8 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s8.json"
import FrontEnd_2_9 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_2/FrontEnd_s9.json"

import FrontEnd_3_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s1.json"
import FrontEnd_3_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s2.json"
import FrontEnd_3_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s3.json"
import FrontEnd_3_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s4.json"
import FrontEnd_3_5 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s5.json"
import FrontEnd_3_6 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s6.json"
import FrontEnd_3_7 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_3/FrontEnd_s7.json"

import FrontEnd_4_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_4/FrontEnd_s1.json"
import FrontEnd_4_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_4/FrontEnd_s2.json"
import FrontEnd_4_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_4/FrontEnd_s3.json"
import FrontEnd_4_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_4/FrontEnd_s4.json"

import FrontEnd_5_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_5/FrontEnd_s1.json"
import FrontEnd_5_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_5/FrontEnd_s2.json"
import FrontEnd_5_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_5/FrontEnd_s3.json"

import FrontEnd_6_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_6/FrontEnd_s1.json"
import FrontEnd_6_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_6/FrontEnd_s2.json"
import FrontEnd_6_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_6/FrontEnd_s3.json"
import FrontEnd_6_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_6/FrontEnd_s4.json"
import FrontEnd_6_5 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_6/FrontEnd_s5.json"

import FrontEnd_7_1 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_7/FrontEnd_s1.json"
import FrontEnd_7_2 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_7/FrontEnd_s2.json"
import FrontEnd_7_3 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_7/FrontEnd_s3.json"
import FrontEnd_7_4 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_7/FrontEnd_s4.json"
import FrontEnd_7_5 from "../../../externalData/MentoringByKanatBaktybek/FrontEnd_7/FrontEnd_s5.json"


export const mentoringByKanatBaktybekMaterials = () => (
  [
    [
      FrontEnd_1_1, FrontEnd_1_2, FrontEnd_1_3, FrontEnd_1_4, FrontEnd_1_5, FrontEnd_1_6, FrontEnd_1_7, FrontEnd_1_8
    ],
    [
      FrontEnd_2_1, FrontEnd_2_2, FrontEnd_2_3, FrontEnd_2_4, FrontEnd_2_5, FrontEnd_2_6, FrontEnd_2_7, FrontEnd_2_8,
      FrontEnd_2_9
    ],
    [
      FrontEnd_3_1, FrontEnd_3_2, FrontEnd_3_3, FrontEnd_3_4, FrontEnd_3_5, FrontEnd_3_6, FrontEnd_3_7
    ],
    [
      FrontEnd_4_1, FrontEnd_4_2, FrontEnd_4_3, FrontEnd_4_4
    ],
    [
      FrontEnd_5_1, FrontEnd_5_2, FrontEnd_5_3
    ],
    [
      FrontEnd_6_1, FrontEnd_6_2, FrontEnd_6_3, FrontEnd_6_4, FrontEnd_6_5
    ],
    [
      FrontEnd_7_1, FrontEnd_7_2, FrontEnd_7_3, FrontEnd_7_4, FrontEnd_7_5
    ]
  ]
)





