import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Alert, Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {setUsersAuthData} from "../../utils/reducers/repoReducer";
import {useDispatch, useSelector} from "react-redux";
import WolfIcon from "../../media/wolf_photo.png";
import RacoonIcon from "../../media/racoon_photo.png";
import BearIcon from "../../media/bear_photo.png";
import FoxIcon from "../../media/fox_photo.png";
import TigerIcon from "../../media/tiger_photo.png";
import styled from "styled-components";
import MainPageTitle from "../../containers/MainPageTitle";
import LoaderWithOverlay from "../LoaderWithOverlay";
import StudentControlPanel from "./StudentControlPanel";
import {CoursePage} from "./CourseTab";
import {StatsPage} from "./StatsPage";

const ContentWrapper = styled('div')`
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const UserPhoto = styled('img')`
  width: 200px;
  border: 1px solid transparent;
  margin-right: 20px;

  @media (max-width: 430px) {
    width: 80px;
    height: 80px;
  }
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: start;
  max-width: 1190px;
  margin: 40px auto 20px;
  padding-left: 24px;
`;

const MenuTab = styled.span`
  padding: 2px 8px;
  font-weight: 500;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  transition: 0.3s;
  border-bottom: 1px solid rgba(25, 118, 210, 0);

  &:hover {
    cursor: pointer;
    color: rgba(25, 118, 210, 0.57);
  }

  &.active {
    transition: 0.3s;
    color: #1976d2;
    border-bottom: 1px solid #1976d2;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TeacherPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { innerWidth: width, innerHeight: height } = window;
  const userData = useSelector(state => state.repos.userData);
  const [isLoading, setIsLoading] = useState(false);
  const userPhotos = [ WolfIcon, RacoonIcon, BearIcon, FoxIcon, TigerIcon ];
  const menuTabs = [
    { id: 0, title: 'Доступы', tabName: 'students', page: <StudentControlPanel /> },
    { id: 1, title: 'Курсы', tabName: 'courses', page: <CoursePage /> },
    { id: 2, title: 'Статистика', tabName: 'stats', page: <StatsPage /> },
  ];
  const [currentTab, setCurrentTab] = useState(menuTabs[0]);

  const exitProfileHandler = () => {
    localStorage.removeItem('st_user_authorized');
    dispatch(setUsersAuthData(JSON.parse(localStorage.getItem('st_user_authorized'))));
    navigate('/');
  }
  const onMenuTabClickHandler = (tabId) => setCurrentTab(menuTabs[tabId]);
  const getMenuTabClass = (tabId) => currentTab.id === menuTabs[tabId].id ? 'active' : '';
  const getMenuTabKey = (index) => `tab-${menuTabs[index].tabName}`;

  return (
    <ContentWrapper>
      <MainPageTitle>Панель управления</MainPageTitle>
      { width <= 500 ? <Button style={{ position: 'relative', left: '78%' }} variant="outlined"  onClick={exitProfileHandler}>Выйти</Button> : null }

      <div style={{display: 'flex', alignItems: 'center', maxWidth: '1190px', margin: '0 auto 20px auto' }}>
        <UserPhoto src={userData?.photoIdRef ? userPhotos[userData?.photoIdRef] : userPhotos[0]} alt=""/>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}>
          <Box>
            <TypographyMobile mobileSize={1} gutterBottom variant="h5" fontSize="18px" component="div" color="text.secondary">Преподаватель</TypographyMobile>
            <TypographyMobile mobileSize={1.5} gutterBottom variant="h3" fontSize="30px"  component="div">{userData?.firstName} {userData?.lastName}</TypographyMobile>
            <TypographyMobile sx={{display: 'flex', alignItems: 'center', marginBottom: 2}} mobileSize={1} fontSize="18px" color="text.secondary" variant="h5">
              email: {userData?.email}
            </TypographyMobile>
          </Box>
          { width >= 500 ? <Button variant="outlined" onClick={exitProfileHandler}>Выйти</Button> : null }
        </Box>
      </div>

      <TypographyMobile mobileSize={1.4} sx={{margin: 4, textAlign: 'center'}} variant="h5" color="text.secondary">Аналитика</TypographyMobile>
      <div style={{ maxWidth: '625px', margin: 'auto' }}>
        <Alert variant="outlined" severity="info" sx={{ marginTop: '20px', display: 'flex' }}>В разработке</Alert>
      </div>

      <MenuContainer>
        {
          menuTabs.map((tab, index) => (
            <MenuTab
              key={getMenuTabKey(index)}
              className={getMenuTabClass(index)}
              onClick={() => onMenuTabClickHandler(index)}
            >
              {menuTabs[index].title}
            </MenuTab>
          ))
        }
      </MenuContainer>

      { currentTab.page }

      { isLoading && <LoaderWithOverlay /> }
    </ContentWrapper>
  )
}

export default TeacherPanel;