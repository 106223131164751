import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainPageTitle from "../../containers/MainPageTitle";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../Loader";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {setShopData, setUserData} from "../../utils/reducers/repoReducer";
import {mainColor} from "../../constants/colors";
import {useSearchParams} from "react-router-dom";

const ContentWrapper = styled('div')`
  @media (max-width: 430px) {
    padding: 0 10px;
  }
`

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`;

const CardMediaMobile = styled(CardMedia)`
  @media (max-width: 430px) {
    width: 120px !important;
    height: 150px !important;
    border-radius: 0 4px 4px 0px!important;
    right: 0!important;
    top: 0!important;
    padding: 0!important;
    
    ${props => props.largeImg ? `
      height: 180px!important;
      width: 100%!important;
    ` : null}
  }
`;

const StoreNavPanelGrid = styled(Grid)`
  display: flex;
  padding: 16px 0 0 16px;
  align-items: center;
  & ul {
    display: flex;
  }
  & li{
    cursor: pointer;
    list-style-type: none;
    margin-right: 18px;
    font-size: 14px;
  }
  @media (max-width: 430px) {
    flex-direction: column!important;
    padding: 0;
    & ul {
      row-gap: 12px;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 0;
    }
  }
`;

const FrontStoreItems = styled(CardContent)`
  position: absolute;
  bottom: 0;
  display: none;
  padding: 16px!important;
  color: white;
  background: ${mainColor};
  width: 100%;
  opacity: 0.9;
  align-items: baseline;
  justify-content: space-between;
`;

const FrontCardContainer = styled('div')`
  &:hover{
    cursor: pointer;
    & div {
      display: flex;
    }
  }
`;

const TagBadge = styled('span')`
  position: absolute;
  padding: 2px 4px;
  background: ${mainColor};
  border-radius: 4px 0;
  color: white;
  opacity: 0.8;
  top: 16px;
  left: 16px;
  font-size: 12px;

  @media (max-width: 430px) {
    top: 0px;
    left: 0px;
    font-size: 0.6rem!important;
  }
`;

const BuyButton = styled(Button)`
  position: absolute!important;
  bottom: 16px;
  right: 16px;
  height: 40px;

  @media (max-width: 430px) {
    font-size: 0.8rem!important;
    padding: 4px 10px;
    height: 34px;
    bottom: 8px;
  }
`;

const GridMobile = styled(Grid)`
  @media (max-width: 430px) {
    padding-left: 0!important;
    & Card > div {
      height: 150px!important;
    }
  }
`;

const CardContentMobile = styled(CardContent)`
  @media (max-width: 430px) {
    padding: 8px!important;
  }
`;

const MainGridMobile = styled(Grid)`
  max-width: 1190px;
  margin: auto!important;
  @media (max-width: 430px) {
    width: 100%!important;
    margin-left: 0!important;
  }
`;

const GStore = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const dispatch = useDispatch();
  const [urlParameters, setUrlParameters] = useSearchParams();
  const urlStartTag = urlParameters.get('tag');
  const shopData = useSelector(state => state.repos.shopData);
  const userData = useSelector(state => state.repos.userData)
  const userAuthData = useSelector(state => state.repos.userAuthData);

  const [storeItems, setStoreItems] = useState([]);

  const buyItem = async (index) => {
    const shopDocRef = doc(db, "shop", userAuthData.uid);
    const userDocRef = doc(db, "users", userAuthData.uid);

    let currentGreenCoinCount = userData.greenCoinAmount - shopData?.items[index].price;

    await updateDoc(userDocRef, {
      greenCoinAmount: currentGreenCoinCount,
    });

    const tempShopData = {...shopData}
    const isApartment = tempShopData.items[index].tag === 'apartment';

    tempShopData.items[index].isSold = true;

    // Если это квартира, то устанавливаем в таблице Users купленную квартиру
    if (isApartment) {
      await updateDoc(userDocRef, {
        purchasedApartment: tempShopData.items[index].name,
      });
    }

    await updateDoc(shopDocRef, {
      items: [
        ...tempShopData.items
      ],
    });
    dispatch(setUserData({
      ...userData,
      greenCoinAmount: currentGreenCoinCount,
      ...(isApartment ? { purchasedApartment: tempShopData.items[index].name} : {})
    }));
    dispatch(setShopData({ ...tempShopData}))
  }

  const selectTagHandler = (e) => {
    if (shopData) {
      if (e.target.title === 'all') setStoreItems([...shopData?.items])
      if (e.target.title === 'asset') setStoreItems([...shopData?.items.filter(el => el.tag === 'asset')])
      if (e.target.title === 'course') setStoreItems([...shopData?.items.filter(el => el.tag === 'course')])
      if (e.target.title === 'apartment') setStoreItems([...shopData?.items.filter(el => el.tag === 'apartment')])
      if (e.target.title === 'other') setStoreItems([...shopData?.items.filter(el => el.tag === 'other')])

      clearSearchParams();
    }
  }

  const clearSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = '';

    // Обновляем URL с пустым полем параметров
    window.history.replaceState({}, '', url);
  };

  const isDisableBuyButton = (index) => (!storeItems[index].isAvailable
    || !(storeItems[index].isSold
      || userData.greenCoinAmount >= storeItems[index].price)
  );

  const buyButtonHandler = (index) => {
    if (storeItems[index].isSold) {
      if (storeItems[index]?.link) window.open(storeItems[index]?.link, '_blank');
    } else {
      const getItemIndexInShopData = () => shopData.items.findIndex(el => storeItems[index].name === el.name)
      buyItem(getItemIndexInShopData());
    }
  };

  const getButtonText = (item, index) => {
    if (storeItems[index].isSold) {
      if (storeItems[index].link) return 'Получить';
      return 'Куплено';
    }
    return `${item.price} GCoin`;
  }

  useEffect(() => {
    if (shopData.items && !storeItems.length) {
      if(urlStartTag) setStoreItems([...shopData?.items.filter(el => el.tag === urlStartTag)]);
      else setStoreItems([...shopData.items])
    }
  }, [shopData]);

  return(
    <>
      <ContentWrapper>
        <MainPageTitle>G-Store</MainPageTitle>
        <MainGridMobile container spacing={2} marginBottom={10} marginTop={4}>
          <StoreNavPanelGrid style={{maxWidth: 1190}} xs={12} >
            <h3>Теги</h3>
            <ul>
              <li onClick={selectTagHandler} title="all">#Все</li>
              {/*<li onClick={selectTagHandler} title="myItems">#Мои</li>*/}
              {/*<li onClick={selectTagHandler} title="available">#Доступные</li>*/}
              <li onClick={selectTagHandler} title="asset">#Ассеты</li>
              <li onClick={selectTagHandler} title="course">#Курсы</li>
              <li onClick={selectTagHandler} title="apartment">#Недвижимость</li>
              <li onClick={selectTagHandler} title="other">#Разное</li>
            </ul>
          </StoreNavPanelGrid>
          {
            shopData?.items ? storeItems.map((el, index) => (
              width > 500 && index < 2 ?
                <GridMobile item xs={6} key={index}>
                  <Card disabled={el.isAvailable}>
                    <FrontCardContainer style={{ display: 'flex', position: 'relative', maxHeight: '340px' }}>
                      <CardMediaMobile
                        component="img"
                        sx={{ width: '100%', padding: '16px', borderRadius: '20px' }}
                        image={el.img}
                        disabled={el.isAvailable}
                        largeImg
                      />
                      <TagBadge>#{el.tagName}</TagBadge>
                      <FrontStoreItems>
                        <TypographyMobile gutterBottom variant="h6" component="div" fontSize="18px" mobileSize={1}>
                          {el.name}
                        </TypographyMobile>
                        <Button
                          variant="contained"
                          color='success'
                          size="large"
                          onClick={() => buyButtonHandler(index)}
                          disabled={isDisableBuyButton(index)}
                        >
                          { getButtonText(el, index) }
                        </Button>
                      </FrontStoreItems>
                    </FrontCardContainer>
                  </Card>
                </GridMobile>
                :
                <GridMobile item xs={width > 500 ? 6 : 12} key={index}>
                  <Card disabled={el.isAvailable}>
                    <div style={{ display: 'flex', position: 'relative' }}>
                      <CardMediaMobile
                        component="img"
                        sx={{ width: '268px', padding: '16px', borderRadius: '20px', height: '194px' }}
                        image={el.img}
                        disabled={el.isAvailable}
                      />
                      <TagBadge>#{el.tagName}</TagBadge>
                      <CardContentMobile sx={{ flex: '1' }}>
                        <TypographyMobile variant="h6" component="div" fontSize="18px" fontWeight="600" mobileSize={0.8}>
                          {el.name}
                        </TypographyMobile>
                        <TypographyMobile variant="body2" color="text.secondary" fontSize="14px" height="42px" mobileSize={0.8}>
                          {el.description.length > (width < 500 ? 70 : 120) ? `${el.description.substr(0, width < 500 ? 70 : 124)}...` : el.description}
                        </TypographyMobile>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', opacity: '0.6' }}>
                        </div>
                        <BuyButton
                          variant="contained"
                          color='success'
                          size="large"
                          onClick={() => buyButtonHandler(index)}
                          disabled={isDisableBuyButton(index)}
                        >
                          { getButtonText(el, index) }
                        </BuyButton>
                      </CardContentMobile>
                    </div>
                  </Card>
                </GridMobile>
            )) : <Loader/>
          }
        </MainGridMobile>
      </ContentWrapper>
    </>
  )
}

export default GStore;