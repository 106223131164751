import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainPageTitle from "../../../containers/MainPageTitle";
import {Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import {Link, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../Loader";
import CustomBadge from "../../CustomBadge";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase";
import {setAdminCourse, setAdminFreelance, setCourseData, setFreelanceData} from "../../../utils/reducers/repoReducer";
import Button from "@mui/material/Button";
import PageContent from "../../../containers/PageContent";
import {getDownloadMaterials} from "../../../data/courseData";
import parse from "html-react-parser";

const ContentWrapper = styled('div')`
  max-width: 1196px;
  margin: auto;
`;

const InteractiveCard = styled(Card)`
  opacity: ${props => props.disabled ? '0.5' : '1'};
  padding: 5px;
  height: 60px;
  position: relative;
  
  &:hover{
    transition: 0.5s;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  @media (max-width: 430px) {
    margin: auto;
  }
`;

const UserDataWrapper = styled('div')`
  padding: 10px;
  position: relative;
  box-shadow: 0px 2px 8px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  margin-bottom: 40px;
  border-radius: 4px;
  
  @media (max-width: 430px) {
    margin: auto;
  }
`;

const AddButton = styled(Button)`
`
const AddContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
  & button {
    margin-right: 10px;
  }
`
const ElementContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px dashed green;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 20px;
`

const LessonCreateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
`;

const LessonTextField = styled(TextField)`
  & input {
    font-weight: 600!important;
  }
`;

const CourseEditPage = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const courseMaterials = useSelector(state => state.repos.courseMaterials);
  const courseData = useSelector(state => state.repos.courseData);

  const currentPageId = 0;

  const [currentPageFlow, setCurrentPageFlow] = useState(null);
  const [currentPageInfo, setCurrentPageInfo] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [courseId, setCourseId] = useState(0);
  const [moduleId, setModuleId] = useState(0);
  const [lectureId, setLectureId] = useState(0);

  const onChange = (value, fieldId, fieldKey) => {
    setCurrentPageFlow(prev => ({
      ...prev,
      [`page${currentPageId+1}`]: prev[`page${currentPageId+1}`]
        .map((el, index) => index === fieldId ? { ...el, [fieldKey]: value } : { ...el}) }))
  }

  const addHandler = (elType) => {
    const newField = elType === 'pageSecondTitles' ? { [elType]: '', num: 0 } : { [elType]: '' };

    setCurrentPageFlow(prev => ({
      ...prev,
      [`page${currentPageId+1}`]: [...prev[`page${currentPageId+1}`], { ...newField }]}));
  }

  const deleteHandler = (fieldId) => {
    setCurrentPageFlow(prev => ({
      ...prev,
      [`page${currentPageId+1}`]: prev[`page${currentPageId+1}`]
        .filter((el, index) => index !== fieldId )}));
  }

  const saveDataHandler = async () => {
    try {
      await updateDoc(doc(db, "courseData", "courses"), {
        [`${courseId}.${moduleId}.${lectureId}.pageCount`]: JSON.stringify(getDownloadMaterials(courseMaterials)[courseId][moduleId][lectureId].pageCount),
        [`${courseId}.${moduleId}.${lectureId}.pageType`]: JSON.stringify(getDownloadMaterials(courseMaterials)[courseId][moduleId][lectureId].pageType),
        [`${courseId}.${moduleId}.${lectureId}.pageFlow`]: JSON.stringify(currentPageFlow),
        [`${courseId}.${moduleId}.${lectureId}.sectionName`]: JSON.stringify(currentPageInfo.sectionName),
      })
      alert('Данные сохранены')
    } catch (e) {
      console.error(e)
      alert('Ошибка при попытке сохранения данных!')
    }
  }

  useEffect(() => {
    const downloadedData = getDownloadMaterials(courseMaterials)?.[courseId]?.[moduleId]?.[lectureId];
    setCurrentPageFlow(downloadedData?.pageFlow);
    setCurrentPageInfo(downloadedData);
  }, [courseMaterials, courseId, moduleId, lectureId])

  const LessonTemplate = () => (
    <>
      <LessonTextField
        fullWidth
        id="outlined-basic"
        defaultValue={currentPageInfo?.sectionName}
        variant="outlined"
        onChange={(e) => {
          setCurrentPageInfo(prev => ({...prev, sectionName: e.target.value}));
        }}
        style={{ marginBottom: '20px' }}
        label="Название урока"
      />
      {
        currentPageFlow[`page${currentPageId+1}`].map((el, index) => <ElementContainer>
          {
            Object.keys(el)[0] === 'pageSecondTitles' ? <TextField
                fullWidth
                id="outlined-basic"
                defaultValue={el.pageSecondTitles}
                variant="outlined"
                onChange={(e) => {
                  onChange(e.target.value, index, "pageSecondTitles");
                }}
                label="Заголовок"
              /> : Object.keys(el)[0] === 'pageText' ? <TextField
                id="filled-multiline-static"
                defaultValue={el.pageText}
                onChange={(e) => {
                  onChange(e.target.value, index, "pageText");
                }}
                fullWidth
                label="Обычный текст"
                multiline
                rows={4}
                variant="outlined"
              /> : Object.keys(el)[0] === 'pageVideo' ? <TextField
                id="outlined-basic"
                label="Ссылка на видео"
                defaultValue={el.pageVideo}
                onChange={(e) => {
                  onChange(e.target.value, index, "pageVideo");
                }}
                variant="outlined"
                fullWidth
              /> : null
          }
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ marginLeft: '20px' }}
            onClick={() => deleteHandler(index)}
          >Удалить</Button>
        </ElementContainer>
        )
      }
    </>
  );

  return(
    <>
      {/*{*/}
      {/*  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>*/}
      {/*    {*/}
      {/*      coursesData.map((elem, index) => (*/}
      {/*        <Grid item xs={ width > 500 ? 6 : 12 } key={index}>*/}
      {/*          <InteractiveCard sx={{ display: 'flex' }}>*/}
      {/*            <CardContent sx={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>*/}
      {/*              <Typography variant="body2" fontSize="16px" height="52px" display="contents">*/}
      {/*                <img style={{ width: '60px', borderRadius: '3px', marginRight: '8px' }} src={elem.iconURL} alt=""/>{elem.name}*/}
      {/*              </Typography>*/}
      {/*            </CardContent>*/}
      {/*          </InteractiveCard>*/}
      {/*        </Grid>*/}
      {/*      ))*/}
      {/*    }*/}
      {/*  </Grid>*/}
      {/*}*/}
      {/*<AddButton*/}
      {/*  variant="outlined"*/}
      {/*  color='success'*/}
      {/*  size="small"*/}
      {/*  onClick={() => {}}*/}
      {/*>+</AddButton>*/}
      <br/>
      <br/>

      <Typography variant="p" textAlign="center" marginBottom="20px" fontSize="12px">
        Курс: {courseData[`course_${courseId}`]?.info?.courseName} <br />
        Модуль: {courseData[`course_${courseId}`]?.info?.modulesName[moduleId]} <br />
        Урок: {getDownloadMaterials(courseMaterials)?.[courseId]?.[moduleId]?.[lectureId]?.sectionName } <br />
      </Typography>
      <br/>
      <br/>


      <Typography variant="h5" textAlign="center" marginBottom="20px">
        Редактирование курса
      </Typography>
      <LessonCreateContainer>
        <TextField
          id="outlined-number"
          label="Номер курса"
          type="number"
          value={courseId}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setCourseId(e.target.value)}
        />
        <TextField
          id="outlined-number"
          label="Номер модуля"
          type="number"
          value={moduleId}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setModuleId(e.target.value)}
        />
        <TextField
          id="outlined-number"
          label="Номер занятия"
          type="number"
          value={lectureId}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setLectureId(e.target.value)}
        />
      </LessonCreateContainer>
      <AddContainer>
            <AddButton
              variant="outlined"
              color='success'
              size="small"
              onClick={() => addHandler("pageSecondTitles")}
            >Заголовок</AddButton>
            <AddButton
              variant="outlined"
              color='success'
              size="small"
              onClick={() => addHandler("pageText")}
            >Обычный текст</AddButton>
            <AddButton
              variant="outlined"
              color='success'
              size="small"
              onClick={() => addHandler("pageVideo")}
            >Видео</AddButton>
            <Button
              variant="contained"
              color='warning'
              size="small"
              onClick={() => setShowPreview(prev => !prev)}
            >Предпросмотр</Button>
            <Button
              variant="contained"
              color='success'
              size="small"
              onClick={() => saveDataHandler()}
            >Сохранить</Button>
          </AddContainer>
      {
        showPreview ? (
          <>
            <ElementContainer style={{ flexDirection: 'column' }}>
              <PageContent pageData={currentPageFlow} currentPageId={currentPageId} currentSectionId={lectureId} />
            </ElementContainer>
            <hr style={{ margin: '80px' }}/>
          </>
        ) : null
      }

      {
        currentPageFlow && <LessonTemplate />
      }

    </>
  )
}

export default CourseEditPage;