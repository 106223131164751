import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({
  mainBtnText = "Алерт диалог",
  mainBtnStyle,
  dialogTitle = "Заголовок",
  dialogDescription = "Описание",
  onAgreeHandler = () => {},
  agreeBtnText = 'Ок',
  onCancelHandler = () => {},
  cancelBtnText = 'Отмена',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} style={mainBtnStyle}>
        {mainBtnText}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{dialogDescription}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            onAgreeHandler();
            handleClose();
          }}>{agreeBtnText}</Button>
          <Button variant="outlined" onClick={() => {
            onCancelHandler();
            handleClose();
          }}>{cancelBtnText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlide;