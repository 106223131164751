import React from 'react';
import styled from "styled-components";
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {findPlatformTrainingStep} from "../../utils/services";
import {useSelector} from "react-redux";

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LeftIcon = styled.img`
  width: 30px;
  margin-right: 15px;
`;

const RightIcon = styled.img`
  width: 30px;
  margin-left: 5px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  ${props => props.pulseHighlight ? `
    & button::before {
      content: '';
      border-radius: 1000px;
      border: 6px solid #ffc107;
      box-shadow: 0 0 60px #ffc107;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: ellipse4 2s infinite;
    }
    & button {
      position: relative;
      z-index: 100;
    }
  
    @keyframes ellipse4 {
      0% {
        width: 30px;
        height: 30px;
        opacity: 1;
      }
      100% {
        width: 400px;
        height: 120px;
        opacity: 0;
      }
    }
  ` : null}
`;

const SnackbarWrapper = styled(SnackbarProvider)`
  & .SnackbarContainer-bottom .SnackbarContainer-right .SnackbarContainer-root {
    margin-bottom: 84px!important;
  }
  & div {
    margin-bottom: 0!important;
  }
`;

function MyApp({ style, awardsType, leftIcon, rightIcon, saveUserAwardHandler, awardBtnDisabled, ...props }) {
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector(state => state.repos.userData);

  const fillAwardsType = awardsType.filter(el => el !== undefined)
  let awardIndex = 0;

  const handleClickVariant = (variant) => () => {
    enqueueSnackbar(ResultMessage(), {
      variant,
      autoHideDuration: 9000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }, });
  };

  const ResultMessage = () => {
    return (
      <MessageWrapper>
        { leftIcon && <LeftIcon src={leftIcon} /> }
        Получена награда +{fillAwardsType[awardIndex]}
        { rightIcon && <RightIcon src={rightIcon[awardIndex]} /> }
      </MessageWrapper>
    )
  }

  return (
    <ButtonWrapper pulseHighlight={findPlatformTrainingStep(userData)?.index === 3}>
      <Button
        variant={style?.variant || "contained"}
        color='success'
        fullWidth={style?.fullWidth}
        disabled={awardBtnDisabled}
        onClick={() => {
          fillAwardsType.map((elem, index) => {
            awardIndex = index;
            handleClickVariant()();
          })
          console.log('получаем награду')
          saveUserAwardHandler(fillAwardsType[0], fillAwardsType[1], fillAwardsType[3])
        }}>Получить награду</Button>
    </ButtonWrapper>
  );
}

const Snackbar = ({ style, awardsType, rightIcon, leftIcon, saveUserAwardHandler, awardBtnDisabled }) => (
  <SnackbarWrapper maxSnack={3}>
    <MyApp
      style={style}
      awardsType={awardsType}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      saveUserAwardHandler={saveUserAwardHandler}
      awardBtnDisabled={awardBtnDisabled}
    />
  </SnackbarWrapper>
)

export default Snackbar;