
export const mainColor = () => {
    return '#212529'
}
export const secondColor = () => {
    return '#ffc107'
}
export const hoverColor = () => {
    return '#4D5154'
}
export const successColor = () => {
    return '#2e7d32'
}
export const inWorkColor = () => {
    return '#1976d2'
}
export const backColor = () => {
    return '#373c412e'
}
export const textColor1 = () => {
    return '#212529'
}
export const textColor2 = () => {
    return '#9A9DA0'
}