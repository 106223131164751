import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Backdrop, Box, Fade, Modal, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../media/logo.png';
import {instructionsToUnlockSections} from "../../utils/services";

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  outline: none;
  width: 60%;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`;

const ModalDescription = styled('div')`
  display: flex;
  align-items: center;
  
  & img {
    width: 300px;
    height: 150px;
  }
`;

const LogoNote = styled('div')`
  display: flex;
  align-items: center;
  color: gray;
  justify-content: space-between;
  & span {
    display: flex;
    align-items: center;
  }
  
  & svg {
    cursor: pointer;
  }

  & img {
    width: 20px;
    margin-right: 10px;
  }
`;

const PlatformTrainingWindow = ({ stepIndex }) => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleOpen();
  }, [stepIndex])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onFocus={() => {}}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalContainer>
            <LogoNote>
              <span><img src={Logo} alt=""/> StuDopolis</span>
              {`${stepIndex+1} / ${instructionsToUnlockSections.length}`}
              <CloseIcon onClick={handleClose} />
            </LogoNote>
            <Typography sx={{ marginTop: '30px', textAlign: 'center' }} variant="h6" component="h2" fontSize="28px" fontWeight="600" id="transition-modal-title">
              {stepIndex + 1}. {instructionsToUnlockSections[stepIndex].title}
            </Typography>
            <ModalDescription style={{ display: 'flex' }}>
              <img src={instructionsToUnlockSections[stepIndex].img} alt=""/>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {instructionsToUnlockSections[stepIndex].description}
              </Typography>
            </ModalDescription>
            <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center', fontWeight: 500 }}>
              {instructionsToUnlockSections[stepIndex].action}
            </Typography>
          </ModalContainer>
        </Fade>
      </Modal>
    </div>
  );
}

export default PlatformTrainingWindow;
