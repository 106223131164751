import React from "react";
import styled from "styled-components";
import {Button, Typography} from "@mui/material";
import GameIcon from "../../../containers/GameIcon/GameIcon";
import {findPlatformTrainingStep} from "../../../utils/services";
import {useSelector} from "react-redux";
import TimerIcon from '@mui/icons-material/Timer';

const QuizPageWrapper = styled.div`
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 25px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  min-height: 400px;
`;

const QuizDetails = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  align-items: center;
  margin: 60px auto;
  font-size: 2vw;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  ${props => props.pulseHighlight ? `
    & button::before {
      content: '';
      border-radius: 1000px;
      border: 6px solid #ffc107;
      box-shadow: 0 0 60px #ffc107;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: ellipse1 2s infinite;
    }
    & button {
      position: relative;
      z-index: 100;
    }
  
    @keyframes ellipse1 {
      0% {
        width: 30px;
        height: 30px;
        opacity: 1;
      }
      100% {
        width: 200px;
        height: 100px;
        opacity: 0;
      }
    }
  ` : null}
`;

const TypographyMobile = styled(Typography)`
  @media (max-width: 430px) {
    ${props => props.mobileSize ? `font-size: ${props.mobileSize}rem!important;` : null}
  }
`

const QuizStartPage = ({ startQuizHandler, pageData, awardRatio = [300, 100], testBtnDisabled}) => {
  const userData = useSelector(state => state.repos.userData);

  return(
    <>
      <QuizPageWrapper>
        <TypographyMobile mobileSize={1} gutterBottom variant="h5" component="div" color="rgba(128, 128, 128, 0.5)" fontSize="2vw" marginBottom="60px">
          Квиз
        </TypographyMobile>
        <TypographyMobile mobileSize={1.4} gutterBottom variant="h3" component="div" textAlign="center" fontSize="3vw">
          Проверь свои способности!
        </TypographyMobile>
        <TypographyMobile mobileSize={0.8} gutterBottom variant="p" component="div" textAlign="center" color="rgba(128, 128, 128, 0.5)" fontSize="1.3vw">
          Ниже указана награда за прохождение квиза на 100%. За каждый неправильный ответ можно получить только 50% от
          стоимости вопроса. Тест на время, помни об этом и удачи!
        </TypographyMobile>

        <QuizDetails>
          <TypographyMobile mobileSize={0.8} gutterBottom variant="h5" component="div" textAlign="center" display="flex" alignItems="center" fontSize="inherit">
            <TimerIcon color="warning" /> {pageData * 20} c.
          </TypographyMobile>
          <TypographyMobile mobileSize={0.8} gutterBottom variant="h5" component="div" textAlign="center" display="flex" alignItems="center" fontSize="inherit">
            +{awardRatio[1]}<GameIcon mobileWidth={35} width={50} icon="2" />
          </TypographyMobile>
          <TypographyMobile mobileSize={0.8} gutterBottom variant="h5" component="div" textAlign="center" display="flex" alignItems="center" fontSize="inherit">
            +{awardRatio[0]}<GameIcon mobileWidth={35} width={50} icon="0" />
          </TypographyMobile>
        </QuizDetails>

        <ButtonWrapper pulseHighlight={findPlatformTrainingStep(userData)?.index === 3}>
          <Button
            variant="contained"
            color='success'
            onClick={startQuizHandler}
            disabled={testBtnDisabled}
          >
            Начать
          </Button>
        </ButtonWrapper>
      </QuizPageWrapper>
    </>
  )
}

export default QuizStartPage;