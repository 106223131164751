import React from "react";
import styled from "styled-components";
import {Divider, Typography} from "@mui/material";
import parse from "html-react-parser";

const SectionContainer = styled.div`
  ${props => props.withImage ? `
    display: flex;
    align-items: center;

    & img {
      max-width: 500px;
      border-radius: 4px;
      margin-right: 20px;
    }
  `: null};

  ${props => props.imgFullWidth ? `
    & img {
      max-width: 100%;
    }
  ` : null}
  
  & p {
    font-size: 16px;
  }
`;

const SecondTitle = styled('div')`
  margin: 20px 0;
  font-weight: 600;
  font-size: 18px;
`;

const Article = ({ article }) => {
  return (
    <>
      <Typography variant="h3" fontSize="28px" fontWeight="600" marginTop="40px">
        {parse(article.articleTitle)}
      </Typography>
      <Divider style={{ margin: '20px 10px' }} />

      {
        article.articleSections.map((section, index) => (
          <SectionContainer style={{ marginTop: index === 0 ? index : '40px' }} withImage={section.img} imgFullWidth={!section.text}>
            {section.img ? (<img src={section.img} alt=""/>) : null}
            {section.secondTitle ? <SecondTitle>{parse(section.secondTitle)}</SecondTitle> : null}
            {section.text ? parse(section.text) : null}
          </SectionContainer>
        ))
      }
    </>
  )
}

export default Article;