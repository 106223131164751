const serverUrl = 'https://studopolis-4p4r.onrender.com';

const Methods = {
  POST: 'POST',
  GET: 'GET',
}

const status = (response) => response.status !== 200
  ? Promise.reject(new Error(response.statusText)) : Promise.resolve(response);

const json = (response) => response.json();
const jwtParse = (response) => response.text();

export const fetchUserSignUp = async (email, password) => {
  await fetch(`${serverUrl}/signup`, {
    method: Methods.POST,
    body: JSON.stringify({
      "email": email,
      "password": password,
      // "role": "tutor"
    })
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchUserSignIn = async (email, password) => {
  await fetch(`${serverUrl}/login`, {
    method: Methods.POST,
    body: JSON.stringify({
      "email": email,
      "password": password,
    })
  }).then(status)
    .then(jwtParse)
    .then(function (data) {
      localStorage.setItem('st_user_auth-token', JSON.stringify(data));
    })
    .catch(function (error) {
      console.log('error', error)
    })
}

export const fetchTutorWhoAmI = async () => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/whoami`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchTutorCreateCourse = async (title) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/create-course`, {
    method: Methods.POST,
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
    body: JSON.stringify({
      "title": title,
    })
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchTutorGetCourses = async () => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/my`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchTutorGetCourseById = async (courseId) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/course/${courseId}`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchTutorCreateLesson = async (courseId, title, video) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/course/${courseId}/create-lesson`, {
    method: Methods.POST,
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
    body: JSON.stringify({
      title,
      video //file!
    })
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchTutorGetListLessons = async (courseId) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/tutor/course/${courseId}/list-lessons`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}

export const fetchStudentGetInfo = async () => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/student`, {
    headers: {
      mode: 'no-cors',
      Authorization: `${JSON.parse(jwt)}`,
      'Access-Control-Allow-Origin': 'true'
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchStudentBuyCourse = async (courseId) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/student/buy`, {
    method: Methods.POST,
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
    body: JSON.stringify({
      'course_id': courseId,
    })
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchStudentGetCourses = async () => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/student/my`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchStudentGetCourseById = async (courseId) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/student/my/${courseId}`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
export const fetchStudentPlayVideo = async (courseId, lessonId) => {
  const jwt = localStorage.getItem('st_user_auth-token');
  await fetch(`${serverUrl}/student/my/${courseId}/p/${lessonId}`, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwt)}`,
    },
  }).then(status)
    .then(json)
    .then(function (data) {
      // dispatch data
    })
    .catch(function (error) {
      console.log('error', error)
    })
}