import firebase, {db} from "../../../firebase";
import {setCoursesInfo, setModuleData, setModuleInfo} from "../../reducers/repoReducer";
import {doc, setDoc} from "firebase/firestore";

//Func works like create/update a module and lecture
export const updateModuleCollection = (courseName, moduleName, fieldName, successCallback, data) => {
  const parentDocRef = firebase.firestore().collection("courseDB").doc(courseName);

  parentDocRef.collection(moduleName).doc(fieldName).set(data, { merge: true })
    .then(function() {
      console.log(`Модуль ${moduleName} с документом ${fieldName} созданы`);
      successCallback();
    })
    .catch(function(error) {
      console.error(`Ошибка добавления модуля ${moduleName}`, error);
    });
}

export const deleteModuleCollection = (courseName, moduleName, successCallback) => {
  const collectionRef = firebase.firestore().collection("courseDB").doc(courseName).collection(moduleName);

  collectionRef.get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.delete();
        console.log("Занятия успешно удалены");
      });
      successCallback();
    })
    .catch(function(error) {
      console.error("Ошибка при получении документов занятий: ", error);
    });
}

export const updateCourseCollection = (courseName, successCallback, data) => {
  const documentRef = firebase.firestore().collection("courseDB").doc(courseName);
  documentRef.set(data, { merge: true })
    .then(function() {
      console.log(`Курс ${courseName} успешно обновлен`);
      successCallback();
    })
    .catch(function(error) {
      console.error(`Ошибка при обновлении курса ${courseName}: `, error);
    });
}

export const deleteCourseCollection = (courseName, successCallback) => {
  const documentRef = firebase.firestore().collection("courseDB").doc(courseName);

  documentRef.delete()
    .then(function() {
      console.log(`Курс ${courseName} успешно удален`);
      successCallback();
    })
    .catch(function(error) {
      console.error(`Ошибка при удалении курса ${courseName}: `, error);
    });
}

export const deleteLectureDocument = (courseName, moduleName, lectureName, successCallback) => {
  const documentRef = firebase.firestore().collection("courseDB").doc(courseName).collection(moduleName).doc(lectureName);

  documentRef.delete()
    .then(function() {
      console.log(`Занятие ${lectureName} успешно удалено`);
      successCallback();
    })
    .catch(function(error) {
      console.error(`Ошибка при удалении занятия ${lectureName}: `, error);
    });
}

export const getCoursesInfoFromDB = async (userData, dispatch) => {
  const coursesDataSnap = await firebase.firestore().collection('courseDB').get();
  const courseDBArray = [];
  coursesDataSnap.forEach((doc) => {
    // let data = doc.data().info.owner === userData.email ? { ...doc.data().info } : { id: doc.data().info.id };
    let data = doc.data().info;
    courseDBArray.push(data);
  });
  dispatch(setCoursesInfo(courseDBArray.sort((a, b) => a.id - b.id)));
}

export const getModuleDataFromDB = async (courseId, moduleId, dispatch) => {
  const moduleDataRef = await firebase.firestore().collection('courseDB').doc(`course_${courseId}`).collection(`module_${moduleId}`).get();
  const moduleDBArray = [];
  moduleDataRef.forEach((doc) => {
    moduleDBArray.push(doc.data());
  });
  dispatch(setModuleData(moduleDBArray));
}

export const unlockCourseByPromoCode = async (promoCode) => {
  const coursesDataSnap = await firebase.firestore()
    .collection('courseDB')
    .where('info.promoCode', '==', promoCode)
    .get();

  const courseDBArray = [];
  coursesDataSnap.forEach((doc) => {
    courseDBArray.push(doc.data());
  });

  return courseDBArray;
}

export const deleteUserData = async (uid) => {
  const collections = ['courses', 'users', 'freelance', 'shop'];
  for (const collectionName of collections) {
    const documentRef = await firebase.firestore().collection(collectionName).doc(uid);
    documentRef.delete()
      .then(() => {
        console.log(`${uid}: ${collectionName} успешно удалено.`);
      })
      .catch((error) => {
        console.error(`Ошибка при удалении документа из коллекции ${collectionName}`, error);
      });
  }
}

export const updateLectureAvailable = async (lectureStruct, uid) => {
  await setDoc(doc(db, "courses", uid), lectureStruct, { merge: true });
  console.log(`${uid + 12}: доступ к уроку успешно обновлен`);
}