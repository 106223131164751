import React, {useEffect, useState} from "react";

import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SignIn";
import { isTeacherAccount } from "../../utils/services";
import { useSelector } from "react-redux";
import News from "../News";
import {fetchStudentGetInfo} from "../../utils/services/fetchRequests";

const Home = () => {
  const userAuthData = useSelector(state => state.repos.userAuthData);
  const [createAccount, setCreateAccount] = useState(false)

  const handleClick = () => {
    setCreateAccount(!createAccount);
  }

  // useEffect(async () => {
  //   await fetchStudentGetInfo();
  // }, [])

  return(
    <>
      {
        userAuthData ? <News /> : (
          createAccount ? <SignUp handleClick={handleClick} />
            : <SignIn handleClick={handleClick} />
        )
      }
      {/*{*/}
      {/*  isTeacherAccount() ? <SignUp /> : null*/}
      {/*}*/}
    </>
  )
}

export default Home;