import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import app from "../../../firebase";
import { setUsersList } from "../../../utils/reducers/repoReducer";
import _ from "lodash";
import { getModuleDataFromDB, updateLectureAvailable } from "../../../utils/services/firestoreHandlers";
import StudentControlPanelView from "./StudentControlPanelView";

const StudentControlPanel = () => {
  const dispatch = useDispatch();
  const { innerWidth: width } = window;
  const usersList = useSelector(state => state.repos.usersList);
  const [searchValue, setSearchValue] = useState('');
  const userData = useSelector(state => state.repos.userData);
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const moduleData = useSelector(state => state.repos.moduleData);
  const [selectedCourseInFilter, setSelectedCourseInFilter] = useState(null);
  const [requestedCourseId, setRequestedCourseId] = useState(null);
  const [selectedModuleInFilter, setSelectedModuleInFilter] = useState(null);
  const [selectedLectureInFilter, setSelectedLectureInFilter] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [isSelectedAllUsers, setIsSelectedAllUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  const isCourseFilterBtnDisabled = _.isEmpty(coursesInfo) || _.isEmpty(userData);
  const isModuleFilterBtnDisabled = _.isEmpty(selectedCourseInFilter);
  const isLectureFilterBtnDisabled = _.isEmpty(selectedCourseInFilter) || _.isEmpty(selectedModuleInFilter);
  const isStudentsRequestBtnDisabled = _.isEmpty(selectedCourseInFilter) || selectedCourseInFilter.id === requestedCourseId;

  const filterHandler = (event) => {
    setSearchValue(event.target.value.toLowerCase());
  }

  const getFilteredCoursesInfo = () => (
    coursesInfo
      .filter(el => el.owner === userData.email)
      .sort((a, b) => b.updatedAt - a.updatedAt)
  );

  const onCourseOptionHandler = (courseId) => setSelectedCourseInFilter(getFilteredCoursesInfo().find(el => el.id === courseId));

  const onModuleOptionHandler = async (moduleId) => {
    if (!_.isEmpty(selectedCourseInFilter)) {
      await getModuleDataFromDB(selectedCourseInFilter.id, moduleId, dispatch);
    }
  }

  const onLectureOptionHandler = (lectureId) => {
    setSelectedLectureInFilter(lectureId !== 'all' ? moduleData.slice(1)[lectureId] : 'all');
  }

  const getCourseDropDownList = () => {
    if (!_.isEmpty(coursesInfo) && !_.isEmpty(userData))
      return getFilteredCoursesInfo().map((el, index) => ({ id: el.id, name: el.name }));
    return [{ id: 'all', name: 'Выберите курс' }];
  }

  const getModulesDropDownList = () => {
    if (!_.isEmpty(selectedCourseInFilter))
      return selectedCourseInFilter.moduleNames.map((el, index) => ({ id: index, name: el }));
    return [{id: 'all', name: '-- модули --'}];
  }

  const getLectureDropDownList = () => {
    if (!_.isEmpty(selectedCourseInFilter) && !_.isEmpty(selectedModuleInFilter) && !_.isEmpty(moduleData))
      return [
        { id: 'all', name: '--все-уроки--' },
        ...moduleData[0].info.lectureNames.map((el, index) => ({ id: index, name: el }))
      ];
    return [{id: 'all', name: '--все-уроки--'}];
  }

  const isStudentWithOpenedMaterial = (student) => {
    const selectedCourseId = selectedCourseInFilter?.id;
    const selectedModuleId = selectedModuleInFilter[0]?.info?.id;
    const selectedLectureId = selectedLectureInFilter === 'all' ? 'all' : selectedLectureInFilter?.id;
    const lectures = student.courses[`course_${selectedCourseId}`]?.modules?.[selectedModuleId]?.lectures;
    if (lectures) {
      if (selectedLectureInFilter === 'all') {
        return Object.values(lectures)?.some(el => el?.lectureAvailable)
      }
      return Object.values(lectures)?.[selectedLectureId]?.lectureAvailable;
    }
    return false;
  };

  const setAvailableToLecture = async (isAvailable, student) => {
    const courseId = selectedCourseInFilter?.id;
    const moduleId = selectedModuleInFilter[0]?.info?.id;

    if (selectedLectureInFilter === 'all') {
      if (isAvailable) {
        student.courses[`course_${courseId}`].modules[moduleId].info.moduleAvailable = isAvailable;
      }
      for(let i = 0; i < moduleData.length; i++) {
        if (student?.courses[`course_${courseId}`]?.modules[moduleId]?.lectures[i]) {
          student.courses[`course_${courseId}`].modules[moduleId].lectures[i].lectureAvailable = isAvailable;
        }
      }
    } else {
      const lectureId = selectedLectureInFilter?.id;

      if (isAvailable) {
        student.courses[`course_${courseId}`].modules[moduleId].info.moduleAvailable = isAvailable;
      }
      if (student?.courses[`course_${courseId}`]?.modules[moduleId]?.lectures[lectureId]) {
        student.courses[`course_${courseId}`].modules[moduleId].lectures[lectureId].lectureAvailable = isAvailable;
      }
    }
    await updateLectureAvailable(student.courses, student.id);
  }

  const lectureOpeningHandler =  async (isAvailable) => {
    try {
      const selectedStudents = studentList.filter(user => user.isSelected);
      selectedStudents.forEach((async (user, index) => {
        await setAvailableToLecture(isAvailable, user);
      }));
    } catch (e) {
      console.log('lectureOpening error', e)
    } finally {
      setStudentList(prev => prev.map(el => ({ ...el, isSelected: false })));
    }
  }

  const getFilteredStudentList = () => (
    studentList.filter(el => {
      if (el.user.firstName.toLowerCase().includes(searchValue)
        || el.user.lastName.toLowerCase().includes(searchValue)
        || el.user.email.toLowerCase().includes(searchValue)
      ) return el.user
    })
  );

  const getFilteredFilteredUsersByCourse = async () => {
    if (selectedCourseInFilter.id && selectedCourseInFilter.id !== requestedCourseId) {
      setLoading(true);
      const filteredUsersList = [];
      const snapshot = await app.firestore().collection('courses')
        .where(`course_${selectedCourseInFilter.id}.info.id`, '==', selectedCourseInFilter.id)
        .get();
      if (snapshot.docs) {
        snapshot.docs.forEach(doc => {
          const userData = usersList.find(el => el.id === doc.id);
          if (doc.data()?.[`course_${selectedCourseInFilter.id}`]?.info?.updatedAt){
            filteredUsersList.push({id: doc.id, courses: doc.data(), user: userData.data(), isSelected: false });
          }
        })
      }
      setStudentList(filteredUsersList);
      setRequestedCourseId(selectedCourseInFilter.id);
      setLoading(false);
    }
  }

  const onSelectAllChange = () => {
    setStudentList(prev => prev.map(el => ({ ...el, isSelected: !isSelectedAllUsers })));
    setIsSelectedAllUsers(prev => !prev);
  };

  const onStudentCardHandler = (index) => {
    setStudentList(prev => prev.map((el) => index === el.id ? ({
      ...el,
      isSelected: !el.isSelected
    }) : el))
  };

  const selectAllValue = `Выбрать всех ${_.isEmpty(studentList) ? '' : `(${studentList?.length})`}`;
  useEffect(async () => {
    const snapshot = await app.firestore().collection('users').get()
    if (snapshot.docs) {
      dispatch(setUsersList(snapshot.docs));
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(moduleData)) {
      setSelectedModuleInFilter(moduleData);
    }
  }, [moduleData]);

  return (
    <StudentControlPanelView
      selectCourseHandler={onCourseOptionHandler}
      selectModuleHandler={onModuleOptionHandler}
      selectLectureHandler={onLectureOptionHandler}
      courseFilterList={getCourseDropDownList()}
      moduleFilterList={getModulesDropDownList()}
      lectureFilterList={getLectureDropDownList()}
      isCourseFilterBtnDisabled={isCourseFilterBtnDisabled}
      isModuleFilterBtnDisabled={isModuleFilterBtnDisabled}
      isLectureFilterBtnDisabled={isLectureFilterBtnDisabled}
      searchFieldValue={searchValue}
      onSearchFieldChange={filterHandler}
      isSelectAllDisabled={_.isEmpty(studentList)}
      isSelectAllChecked={isSelectedAllUsers}
      onSelectAllChange={onSelectAllChange}
      selectAllValue={selectAllValue}
      showAccessBtn={studentList.find(el => el.isSelected)}
      accessBtnHandler={(isAvailable) => lectureOpeningHandler(isAvailable)}
      studentsRequestHandler={getFilteredFilteredUsersByCourse}
      isStudentsRequestBtnDisabled={isStudentsRequestBtnDisabled}
      isStudentListLoading={loading}
      isStudentListEmpty={_.isEmpty(studentList)}
      onStudentCardHandler={onStudentCardHandler}
      windowWidth={width}
      isCardChecked={(index) => studentList.find(el => el.id === index)?.isSelected}
      studentList={getFilteredStudentList()}
      isOpenedLecture={isStudentWithOpenedMaterial}
    />
  );
}

export default StudentControlPanel;