import React from "react";
import Button from "@mui/material/Button";
import AlertDialogSlide from "../../AlertDialogSlide";
import styled from "styled-components";

const DeleteBtnStyle = {
  border: 'none',
  padding: '4px',
  fontSize: '12px',
  color: 'gray'
}

const OptionContainer = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`;

const TeacherOptionLine = ({
  onSaveHandler,
  onDeleteHandler,
  deleteMainBtnText = "Удалить курс",
  deleteTitleText = "Уверены, что хотите удалить курс?",
}) => {
  return (
    <OptionContainer>
      <AlertDialogSlide
        mainBtnText={deleteMainBtnText}
        mainBtnStyle={DeleteBtnStyle}
        dialogTitle={deleteTitleText}
        dialogDescription = "Удаление материала займет некоторое время"
        onAgreeHandler={onDeleteHandler}
        agreeBtnText="Удалить"
      />
      <Button
        variant="contained"
        color='success'
        style={{ width: '200px' }}
        onClick={onSaveHandler}
      >
        Сохранить
      </Button>
    </OptionContainer>
  )
}

export default TeacherOptionLine;