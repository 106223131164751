import {unity3DaysIntensiveMaterials} from "./Unity3DaysIntensive";
import {frontendMaterials} from "./FrontEnd";
import {minecraftMaterials} from "./Minecraft";
import {pythonMaterials} from "./Python";
import {modelingMaterials} from "./Modelling";
import {webDesignMaterials} from "./WebDesign";
import {unityMaterials} from "./Unity";
import {digitalArtMaterials} from "./DigitalArt";
import {unrealEngineMaterials} from "./UnrealEngine";
import {kspMaterials} from "./KerbalSpace";
import {cSharpMaterials} from "./CSharp";
import {targetMaterials} from "./Target";
import {mentoringByKanatBaktybekMaterials} from "./MentoringByKanatBaktybek";

export const materialCollection = () => [
  unity3DaysIntensiveMaterials(),
  cSharpMaterials(),
  frontendMaterials(),
  minecraftMaterials(),
  pythonMaterials(),
  modelingMaterials(),
  webDesignMaterials(),
  unityMaterials(),
  digitalArtMaterials(),
  unrealEngineMaterials(),
  kspMaterials(),
  targetMaterials(),
  mentoringByKanatBaktybekMaterials(),
]

export const getDownloadMaterials = (courseMaterials) => {
  return courseMaterials.length ? courseMaterials : materialCollection();
}