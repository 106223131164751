import React, {useEffect, useState} from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import Home from "./component/Home/Home";
import StudyPlatform from "./component/Learning";
import Navbar from "./component/Header/Navbar";
import Career from "./component/Career";
import Admin from "./component/Admin";
import Rating from "./component/Rating";
import Footer from "./component/Footer";

import {AuthProvider} from "./context/AuthContext";
import {mainColor} from "./constants/colors";
import firebase, {app, db} from './firebase'
import Courses from "./component/Courses";
import Modules from "./component/Modules";
import Freelance from "./component/Freelance";
import {addDoc, doc, getDoc, setDoc, Timestamp, updateDoc} from "firebase/firestore";
import Messages from "./component/Messages";
import FreelanceTask from "./component/FreelanceTask";
import {useDispatch, useSelector} from "react-redux";
import {
  setCourseData, setCourseDB, setCourseMaterials, setCoursesInfo,
  setFreelanceData,
  setShopData,
  setUserData,
  setUsersAuthData
} from "./utils/reducers/repoReducer";
import GStore from "./component/GStore";
import News from "./component/News";
import teacherData from "./component/Header/Navbar/teacherData.json";
import Student from "./component/Admin/Student";
import LectureProgress from "./component/Admin/LectureProgress";
import {SnackbarProvider} from 'notistack';
import {
  findPlatformTrainingStep,
  getCurrentTimeInMoscow,
  instructionsToUnlockSections,
  UnAuthorizedSnackbarBox
} from "./utils/services";
import {createDBArchitecture} from "./utils/services/createCourseDBArchitecture";
import {createFreelanceDBArchitecture} from "./utils/services/createFreelanceDBArchitecture";
import ChatGPTAssistant from "./component/ChatGPT";
import PlatformTrainingWindow from "./component/ModalWindow";
import Profile from "./component/Home/Profile";
import {serviceEconomics} from "./utils/services/ServiceEconomics";
import item_5 from "./media/platformer2D_screen.png";
import item_3 from "./media/apk.png";
import flat_default from "./media/apartments/flat_default.jpg";
import flat_economy_1 from "./media/apartments/flat_economy_1.jpg";
import flat_economy_2 from "./media/apartments/flat_economy_2.jpg";
import flat_standard_1 from "./media/apartments/flat_standard_1.jpg";
import flat_standard_2 from "./media/apartments/flat_standard_2.jpg";
import flat_premium_1 from "./media/apartments/flat_premium_1.jpg";
import flat_lux_1 from "./media/apartments/flat_lux_1.jpg";
import flat_lux_2 from "./media/apartments/flat_lux_2.jpg";
import flat_lux_3 from "./media/apartments/flat_lux_3.jpg";
import item_2 from "./media/asset.png";
import unity_icon from "./media/courseWallpaper/unity.png";
import frontend_icon from "./media/courseWallpaper/frontend.png";
import minecraft_icon from "./media/courseWallpaper/minecraft.png";
import python_icon from "./media/courseWallpaper/python.png";
import figma_icon from "./media/courseWallpaper/figma.png";
import adobe_illustrator_icon from "./media/courseWallpaper/adobe_illustrator.png";
import blender_icon from "./media/courseWallpaper/blender.png";
import unreal_engine_icon from "./media/courseWallpaper/unreal_engine.png";
import ksp_icon from "./media/courseWallpaper/kerbal_space_programm.png";
import unity3DaysIntensive from "./media/courseWallpaper/unity_3Days_intensive.png";
import Dashboard from "./component/Dashboard";
import {materialCollection} from "./data/courseData";
import {ROLES_LIST} from "./constants/constants";
import TeacherPanel from "./component/TeacherPanel";
import CoursePanel from "./component/TeacherPanel/CoursePanel";
import ModulePanel from "./component/TeacherPanel/ModulePanel";
import unity_3Days_intensive from "./media/courseWallpaper/unity_3Days_intensive.png";
import {coursesData} from "./externalData";
import {
  createModuleCollection, deleteCourseCollection,
  deleteModuleCollection, getCoursesInfoFromDB,
  updateCourseCollection
} from "./utils/services/firestoreHandlers";
import _ from "lodash";

const AppStyle = styled('div')`
  padding-left: 100px;
  color: ${mainColor};
  position: relative;
  min-height: 100%;
  padding-bottom: 120px;

  @media (max-width: 430px) {
    padding: 0 10px 80px 10px;
  }
`

const SnackbarProviderContainer = styled(SnackbarProvider)`
  @media (max-width: 430px) {
    margin-bottom: 60px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0) !important;
  }
`;

const smartUpdateDBStructure = (userStructure, updatedStructure, structName, userAuthData, isMissingStructure, reduxAction, bannedFields, dispatch) => {
  function copyValuesWithDifferences(obj1, obj2, bannedFields) {
    // for (let key in obj1) {
    //   if (typeof obj1[key] === 'object') {
    //     if (!obj2[key]) {
    //       obj2[key] = {};
    //     }
    //     copyValuesWithDifferences(obj1[key], obj2[key], differences ? differences[key] : null);
    //   } else {
    //     obj2[key] = differences && differences[key] !== undefined ? differences[key] : obj1[key];
    //   }
    // }
    for (let key in obj1) {
      if (typeof obj1[key] === 'object' && obj1[key] !== null) {
        if (!obj2[key]) {
          obj2[key] = {};
        }
        copyValuesWithDifferences(obj1[key], obj2[key], bannedFields);
      } else {
        if (!(bannedFields.length && bannedFields.includes(key))) obj2[key] = obj1[key];
      }
    }
  }

  function compareObjectsByKeys(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return true;
      }
    }

    return false;
  }

  const saveInDBNewStructure = async () => {
    await setDoc(doc(db, structName, userAuthData.uid), updatedStructure);
    dispatch(reduxAction(updatedStructure));
  }
  // console.log(compareObjectsByKeys(userStructure, updatedStructure))

  if (isMissingStructure) {
    saveInDBNewStructure();
    // console.log(`${structName} отсутстует: сохранение новой в бд`);
  } else {
    if (compareObjectsByKeys(userStructure, updatedStructure)) {
      copyValuesWithDifferences(userStructure, updatedStructure, bannedFields)
      saveInDBNewStructure();
      // console.log(`${structName} обновлена: сохранение обновленной в бд`)
    } else {
      // console.log(`${structName} не изменена`)
      dispatch(reduxAction(userStructure));
    }
  }
};
// ['courseName', 'modulesName']
const App = () => {
  const userAuthData = useSelector(state => state.repos.userAuthData);
  const userData = useSelector(state => state.repos.userData);
  const courseData = useSelector(state => state.repos.courseData);
  const courseMaterials = useSelector(state => state.repos.courseMaterials);
  const coursesInfo = useSelector(state => state.repos.coursesInfo);
  const dispatch = useDispatch();
  const [currentModuleId, setCurrentModuleId] = useState(0)

  const getPaidCoursesLength = () => courseData && Object.values(courseData).filter((el, index) => el.info.courseAvailable && !el.info.isOpenByDefault).length;

  useEffect(() => {
    dispatch(setUsersAuthData(JSON.parse(localStorage.getItem('st_user_authorized'))));
  }, [])

  function convertToThreeDimensionalArray(course) {
    const staticMaterials = materialCollection();

    for (let i = 0; i < staticMaterials.length; i++){
      for (let j = 0; j < staticMaterials[i].length; j++){
        for (let k = 0; k < staticMaterials[i][j].length; k++){
          if (course[i]?.[j]?.[k]) {
            // staticMaterials[i][j][k].pageCount = JSON.parse(course[i][j][k].pageCount);
            // staticMaterials[i][j][k].pageType = JSON.parse(course[i][j][k].pageType);
            staticMaterials[i][j][k].pageFlow = JSON.parse(course[i][j][k].pageFlow);
            staticMaterials[i][j][k].sectionName = JSON.parse(course[i][j][k].sectionName);
          }
        }
      }
    }
    return staticMaterials;
  }
  useEffect(async () => {
    if (userAuthData) {

      const courseSnap = await getDoc(doc(db, "courses", userAuthData.uid))
      if (courseSnap.exists()) {
        dispatch(setCourseData(courseSnap.data()));
        // console.log('данные юзера', courseSnap.data())
        // console.log('данные ожидаемой структуры', createDBArchitecture())
        // smartUpdateDBStructure(courseSnap.data(), createDBArchitecture(), "courses", userAuthData, false, setCourseData, ['courseName', 'modulesName'], dispatch);
      } else {
        // smartUpdateDBStructure(courseSnap.data(), createDBArchitecture(), "courses", userAuthData, true, setCourseData, ['courseName', 'modulesName'], dispatch);
        console.log("Не найдено courseSnap!");
      }

      const userDataSnap = await getDoc(doc(db, "users", userAuthData.uid))
      if (userDataSnap.exists()) {
        dispatch(setUserData(userDataSnap.data()));
        await getCoursesInfoFromDB(userDataSnap.data(), dispatch);
      } else {
        console.log("Не найдено userDataSnap!");
      }

      const freelanceDataSnap = await getDoc(doc(db, "freelance", userAuthData.uid))
      if (freelanceDataSnap.exists()) {
        // smartUpdateDBStructure(freelanceDataSnap.data(), createFreelanceDBArchitecture(), "freelance", userAuthData, false, setFreelanceData, ['courseName', 'modulesName'], dispatch);
        dispatch(setFreelanceData(freelanceDataSnap.data()));
      } else {
        smartUpdateDBStructure(freelanceDataSnap.data(), createFreelanceDBArchitecture(), "freelance", userAuthData, true, setFreelanceData, ['courseName', 'modulesName'], dispatch);
        console.log("Не найдено freelanceDataSnap!");
      }

      const shopDataSnap = await getDoc(doc(db, "shop", userAuthData.uid))
      if (shopDataSnap.exists()) {
        dispatch(setShopData(shopDataSnap.data()));
      } else {
        console.log("Не найдено shopDataSnap!");
      }

      const courseMaterialsSnap = await getDoc(doc(db, "courseData", "courses"))
      if (courseMaterialsSnap.exists()) {
        // Включить после работ!
        dispatch(setCourseMaterials(convertToThreeDimensionalArray(courseMaterialsSnap.data())));
      } else {
        console.log("Не найдено courseMaterialsSnap!");
      }
    }
  }, [userAuthData])

  useEffect(async () => {
    if (!_.isEmpty(courseData) && coursesInfo.length) {
      const updatedCoursesInfo = coursesInfo.filter(course => courseData[`course_${course.id}`]?.info?.updatedAt < course.updatedAt); // find available fresh coursesInfo
      // Обновить updatedAt
      if (updatedCoursesInfo.length) {
        const newCourseData = {...courseData};
        updatedCoursesInfo.forEach(course => {
          const courseLayout = newCourseData[`course_${course.id}`];

          newCourseData[`course_${course.id}`] = {
            ...courseLayout,
            info: {
              ...courseLayout.info,
              updatedAt: course.updatedAt,
            },
          }

          for (let i = 0; i < course?.moduleNames?.length; i++) {
            const moduleLayout = newCourseData[`course_${course.id}`][`modules`]?.[i];

            newCourseData[`course_${course.id}`][`modules`][i] = {
              ...(moduleLayout?.lectures ? ({...moduleLayout}) : ({ lectures: {} })),
              info: {
                ...moduleLayout?.info,
                moduleAvailable: moduleLayout?.info?.moduleAvailable || true,
              },
            }
            for (let j = 0; j < course?.lectureCount[i]; j++) {
              const lectureLayout = newCourseData[`course_${course.id}`][`modules`]?.[i]?.[`lectures`]?.[j];

              newCourseData[`course_${course.id}`][`modules`][i][`lectures`][j] = {
                ...lectureLayout,
                lectureAvailable: lectureLayout?.lectureAvailable || true,
                pageProgress: lectureLayout?.pageProgress || '0000000000',
                isAwardReceived: lectureLayout?.isAwardReceived || false,
              }
            }
          }
        })

        try {
          await setDoc(doc(db, "courses", userAuthData.uid), newCourseData, {merge: true});

          const courseSnap = await getDoc(doc(db, "courses", userAuthData.uid))
          if (courseSnap.exists()) {
            dispatch(setCourseData(courseSnap.data()));
          } else {
            console.log("Не найдено courseSnap!");
          }
        } catch (e) {
          console.log('Error: ', e);
        }
      }
    }
  }, [coursesInfo]);

  return (
    <AuthProvider>
      <Navbar />
      {
        userAuthData?.uid && userData?.platformTraining && !userData?.platformTraining?.isOpenNews ? (
          <PlatformTrainingWindow stepIndex={findPlatformTrainingStep(userData)?.index} />
          ) : null
      }
      <AppStyle>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='administration' element={ userData.role === ROLES_LIST.ADMIN ? <Admin /> : null}/>
          <Route path='administration/student' element={ userData.role === ROLES_LIST.ADMIN ? <Student /> : null}/>
          <Route path='administration/student/progress' element={ userData.role === ROLES_LIST.ADMIN ? <LectureProgress /> : null}/>
          <Route path='teacher' element={ userData.role === ROLES_LIST.TEACHER ? <TeacherPanel /> : null}/>
          <Route path='teacher/course' element={ userData.role === ROLES_LIST.TEACHER ? <CoursePanel /> : null}/>
          <Route path='teacher/course/module' element={ userData.role === ROLES_LIST.TEACHER ? <ModulePanel/> : null}/>
          {
            userAuthData?.uid ? (
              <>
                <Route path='profile' element={<Profile />} />
                <Route path='rating' element={<Rating />} />
                {/*<Route path='freelance' element={<Freelance />}/>*/}
                {/*<Route path='freelance/task' element={<FreelanceTask />}/>*/}
                <Route path='shop' element={<GStore />}/>
                <Route path='course' element={<Modules setCurrentModuleId={setCurrentModuleId} />} />
                <Route path='module' element={<StudyPlatform />} />
                {/*<Route path='messages' element={<Messages />}/>*/}
                {/*<Route path='career' element={<Career />}/>*/}
                {/*<Route path='news' element={<News />}/>*/}
              </>
            ) : null
          }
        </Routes>
        {/*Отключаем ChatGPT*/}
        {/*{*/}
        {/*  userAuthData?.uid && getPaidCoursesLength() ? (*/}
        {/*    <ChatGPTAssistant />*/}
        {/*  ) : null*/}
        {/*}*/}
        {/*{*/}
        {/*  !userAuthData?.uid ? (*/}
        {/*    <SnackbarProviderContainer persist>*/}
        {/*      <UnAuthorizedSnackbarBox />*/}
        {/*    </SnackbarProviderContainer>*/}
        {/*  ) : null*/}
        {/*}*/}
      </AppStyle>
    </AuthProvider>
  );
}

export default App;
